import { Text } from '@vouched.id/vault';
import React, { FC } from 'react';

export const DLVCaption: FC = () => (
  <Text style={{ marginBottom: '15px' }}>
    US Driver's License Validation (DLV) validates the printed data fields on
    the driver’s license with government or proprietary data sources. All
    available fields must pass for this section to be approved.
  </Text>
);
