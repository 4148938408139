import { StyleObject, Text } from '@vouched.id/vault';
import React, { FC } from 'react';
import { JobStatus } from '../../utils';
import { ReviewDropdowns } from '../Review/ReviewDropdowns';
import { ScoresDropdown } from '../Scores/ScoresDropdown';
import { useJobSectionTitleStyles } from '../utils';
import { SelfieVerificationCompletedResult } from './SelfieVerificationCompletedResult';
import { JobError, JobResult, JobUpdateReview } from 'models/jobs/jobs';
import { roundNumber } from 'utils/ui.utils';

interface SelfieVerificationDropdownsProps {
  readonly jobResult?: JobResult;
  readonly jobErrors: JobError[];
  readonly jobStatus: JobStatus;
  readonly jobReview: JobUpdateReview | null;
  readonly setJobReviewSuccess: (success: boolean | null) => void;
  readonly jobSid: string;
}

export const SelfieVerificationDropdowns: FC<
  SelfieVerificationDropdownsProps
> = ({
  jobResult,
  jobErrors,
  jobStatus,
  jobReview,
  setJobReviewSuccess,
  jobSid
}) => {
  const confidences = jobResult?.confidences;
  const jobSectionTitleStyles = useJobSectionTitleStyles();

  return (
    <>
      {!!jobResult && (
        <SelfieVerificationCompletedResult
          jobErrors={jobErrors}
          jobResult={jobResult}
          jobStatus={jobStatus}
        />
      )}
      <Text style={jobSectionTitleStyles as StyleObject}>Other</Text>
      {!!confidences && (
        <ScoresDropdown selfie={roundNumber(confidences.selfie) ?? undefined} />
      )}
      {!!confidences && (
        <ReviewDropdowns
          jobConfidences={confidences}
          jobErrors={jobErrors}
          jobReview={jobReview}
          jobSid={jobSid}
          setJobReviewSuccess={setJobReviewSuccess}
        />
      )}
    </>
  );
};
