import { Box, Text } from '@vouched.id/vault';
import React, { FC, useMemo } from 'react';
import { itemHasErrorOrWarning, JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import { DropdownItemsSection } from '../DropdownItemsSection';
import { SingleSpanTable } from '../SingleSpanTable';
import {
  addIconsToDropdownItems,
  ConfidenceDropdownItem,
  DropdownItemTypes,
  DropdownStatusIcon
} from '../utils';
import { IpAddressJobResult, JobError } from 'models/jobs/jobs';

interface IPDropdownProps {
  readonly jobErrors: JobError[];
  readonly jobStatus: JobStatus;
  readonly ipAddressResults: IpAddressJobResult & { address?: string };
}

export const IPDropdown: FC<IPDropdownProps> = ({
  jobErrors,
  jobStatus,
  ipAddressResults
}) => {
  const { dropdownHasWarnings, dropdownHasRejections, dropdownItems } =
    useMemo(() => {
      const dropdownItemsWithoutIcons: ConfidenceDropdownItem[] = [
        {
          text: 'IP Address',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          available: true,
          hasErrorOrWarning: itemHasErrorOrWarning(jobErrors, 'ipAddressError')
        }
      ];

      return addIconsToDropdownItems(dropdownItemsWithoutIcons);
    }, [jobErrors, itemHasErrorOrWarning, addIconsToDropdownItems]);

  return (
    <Dropdown
      statusIcon={
        <DropdownStatusIcon
          dropdownHasRejections={dropdownHasRejections}
          dropdownHasWarnings={dropdownHasWarnings}
          jobStatus={jobStatus}
        />
      }
      title="IP"
    >
      <Box>
        <Text>IP Address Check</Text>
        <DropdownItemsSection dropdownItems={dropdownItems} />
        <SingleSpanTable
          items={[
            {
              label: 'Address',
              value: ipAddressResults.address
            },
            {
              label: 'Type',
              value: ipAddressResults.userType
            },
            {
              label: 'Anonymous',
              value: ipAddressResults.isAnonymous ? 'True' : 'False'
            },
            {
              label: 'City',
              value: ipAddressResults.city
            },
            {
              label: 'State',
              value: ipAddressResults.state
            },
            {
              label: 'Country',
              value: ipAddressResults.country
            },
            {
              label: 'ISP',
              value: ipAddressResults.isp
            },
            {
              label: 'Organization',
              value: ipAddressResults.organization
            },
            {
              label: 'Confidence',
              value: ipAddressResults.confidence
            },
            {
              label: 'Warnings',
              value: ipAddressResults.warnings
            }
          ]}
          numColumns={2}
        />
      </Box>
    </Dropdown>
  );
};
