import { Collapse } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import { InvoiceList } from './invoice-list';
import { ManageSubscription } from './manage-subscription';
import { PaymentMethods } from './payment-methods';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { requestUpgrade } from 'store/sagas/sagas';
import './billing-payments.css';
import { isSelfServiceAccount } from 'utils/billing';

const stripePromise = loadStripe(process.env['REACT_APP_STRIPE_PK'] ?? '');

export const BillingPayments: React.FC = () => {
  const { state, dispatch, asyncDispatch } = useGlobalState();
  const [count, setCount] = useState(0);
  const [collapsibleOpen, setCollapsibleOpen] = useState(true);

  const [upgradeRequested, setUpgradeRequested] = useState(false);
  const [selection, setSelection] = useState('Essential');

  const toggleCollapsible = () => {
    setCollapsibleOpen(!collapsibleOpen);
  };

  const handleSelection = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelection(event.target.value);
    },
    []
  );

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!state.account) {
      return;
    }

    asyncDispatch(requestUpgrade(state.account.id, selection))
      .then(() => {
        setUpgradeRequested(true);
        dispatch(
          setMessages({
            value: `Your Upgrade Has Been Requested`,
            severity: 'success'
          })
        );
      })
      .catch(console.error);
  };

  return state.account ? (
    <div className="full-page">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 5
        }}
      >
        <h1 className="g-page-name">Your account</h1>

        <div className="billing-tabs-div">
          <div
            onClick={() => {
              setCount(0);
            }}
            style={{
              display: 'flex',
              width: '22%',
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom: 'solid',
              borderWidth: count === 0 ? '4px' : '2px',
              borderColor: count === 0 ? '#635BFF' : 'inherit',
              padding: '10px'
            }}
          >
            <span
              style={{
                fontSize: '13px',
                color: count === 0 ? '#635BFF' : 'inherit',
                lineHeight: '18px',
                fontWeight: 600
              }}
            >
              Subscription
            </span>
          </div>
          <div
            onClick={() => {
              setCount(1);
            }}
            style={{
              display: 'flex',
              width: '22%',
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom: 'solid',
              borderWidth: count === 1 ? '4px' : '2px',
              borderColor: count === 1 ? '#635BFF' : 'inherit',
              padding: '10px'
            }}
          >
            <span
              style={{
                fontSize: '13px',
                color: count === 1 ? '#635BFF' : 'inherit',
                lineHeight: '18px',
                fontWeight: 600
              }}
            >
              Payment Methods
            </span>
          </div>
          <div
            onClick={() => {
              setCount(2);
            }}
            style={{
              display: 'flex',
              width: '22%',
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom: 'solid',
              borderWidth: count === 2 ? '4px' : '2px',
              borderColor: count === 2 ? '#635BFF' : 'inherit',
              padding: '10px'
            }}
          >
            <span
              style={{
                fontSize: '13px',
                color: count === 2 ? '#635BFF' : 'inherit',
                lineHeight: '18px',
                fontWeight: 600
              }}
            >
              Invoices
            </span>
          </div>
          <div
            onClick={() => {
              setCount(3);
            }}
            style={{
              display: 'flex',
              width: '22%',
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom: 'solid',
              borderWidth: count === 3 ? '4px' : '2px',
              borderColor: count === 3 ? '#635BFF' : 'inherit',
              padding: '10px'
            }}
          >
            <span
              style={{
                fontSize: '13px',
                color: count === 3 ? '#635BFF' : 'inherit',
                lineHeight: '18px',
                fontWeight: 600
              }}
            >
              Manage Subscription
            </span>
          </div>
          <div
            onClick={() => {
              setCount(4);
            }}
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom: 'solid',
              borderWidth: count === 4 ? '4px' : '2px',
              borderColor: count === 4 ? '#635BFF' : 'inherit',
              padding: '16px'
            }}
          />
        </div>

        {count === 0 && (
          <div className="billing-container">
            <div>
              <div>
                {isSelfServiceAccount(state.account) && (
                  <div
                    style={{
                      background: 'white',
                      borderRadius: '5px',
                      padding: '15px',
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: '2vh'
                    }}
                  >
                    <div>
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: '24px',
                          color: '#444444',
                          lineHeight: '28px'
                        }}
                      >
                        Base subscription
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: '24px',
                          color: collapsibleOpen ? '#999999' : '#444444',
                          lineHeight: '28px'
                        }}
                      >
                        $300/month
                      </span>
                      <div onClick={toggleCollapsible}>
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                            color: '#999999',
                            lineHeight: '24px'
                          }}
                        >
                          What's included?
                        </span>
                        <i
                          className={
                            collapsibleOpen
                              ? cx('fa fa-angle-up')
                              : cx('fas fa-angle-down')
                          }
                          style={{
                            color: '#999999',
                            fontSize: '15px',
                            padding: '0px 5px'
                          }}
                        />
                      </div>
                    </div>
                    <Collapse in={collapsibleOpen}>
                      <div style={{ marginTop: '1vh' }}>
                        <div style={{ marginBottom: '2vh' }}>
                          <span
                            style={{
                              fontWeight: 500,
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: '#444444'
                            }}
                          >
                            Verify and onboard new customers, patients, partners
                            and gig contractors in seconds. With Vouched ID
                            verification, you can expand your reach while
                            keeping the bad guys out.
                          </span>
                        </div>
                        <div
                          style={{
                            padding: '10px 0px',
                            borderTop: 'solid 1px #eeeeee'
                          }}
                        />
                        <div>
                          <div style={{ marginBottom: '2vh' }}>
                            <span
                              style={{
                                fontWeight: 800,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#999999'
                              }}
                            >
                              Included features
                            </span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <div
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  lineHeight: '24px',
                                  color: '#444444'
                                }}
                              >
                                Expert ID Document Examination
                              </div>
                              <div
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  lineHeight: '24px',
                                  color: '#444444'
                                }}
                              >
                                Facial Recognition, Liveness Detection
                              </div>
                              <div
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  lineHeight: '24px',
                                  color: '#444444'
                                }}
                              >
                                Data Extraction
                              </div>
                              <div
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  lineHeight: '24px',
                                  color: '#444444'
                                }}
                              >
                                Dashboard Access
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <div
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  lineHeight: '24px',
                                  color: '#444444'
                                }}
                              >
                                Anonymous Activity
                              </div>
                              <div
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  lineHeight: '24px',
                                  color: '#444444'
                                }}
                              >
                                Analysis of email, phone, address, name
                              </div>
                              <div
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  lineHeight: '24px',
                                  color: '#444444'
                                }}
                              >
                                Email age and fraud risk
                              </div>
                              <div
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  lineHeight: '24px',
                                  color: '#444444'
                                }}
                              >
                                Phone source
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                    <h3>Request Account Upgrade</h3>
                    {upgradeRequested ? (
                      <p>
                        Request received! Our compliance team will evaluate your
                        request and we'll be in touch soon. Please email
                        support@vouched.id with any questions.
                      </p>
                    ) : (
                      <form id="account-upgrade-form" onSubmit={handleSubmit}>
                        <label htmlFor="package"> Upgrade to: </label>
                        <select
                          id="package"
                          name="package"
                          onChange={handleSelection}
                          value={selection}
                        >
                          <option value="Essential">Essential </option>
                          <option value="Advanced">Advanced </option>
                          <option value="Premier">Premier </option>
                        </select>
                        <button
                          className="upgrade-button"
                          form="account-upgrade-form"
                          type="submit"
                          value="Submit"
                        >
                          Request
                        </button>
                      </form>
                    )}
                    <p>
                      View our packages{' '}
                      <a
                        href="https://www.vouched.id/for-developers/pricing/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        here
                      </a>
                    </p>
                  </div>
                )}
                {!isSelfServiceAccount(state.account) && (
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#444444',
                      marginBottom: '2vh'
                    }}
                  >
                    Your subscription is managed by the Vouched team. Please
                    reach out to support if you have any questions!
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {count === 1 && (
          <div className="billing-container">
            <Elements stripe={stripePromise}>
              <PaymentMethods />
            </Elements>
          </div>
        )}
        {count === 2 && <InvoiceList />}
        {count === 3 && (
          <div>
            <ManageSubscription />
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="billing-unavailable">
      This page is unavailable because your billing is being managed by the
      Vouched team. Reach out to support to ask about getting set up with
      automatic billing
    </div>
  );
};
