import { Text } from '@vouched.id/vault';
import React, { FC } from 'react';

export const CrossCheckCaption: FC = () => {
  return (
    <Text style={{ marginBottom: '15px' }}>
      This section shows results from comparing ID data against multiple
      proprietary databases, giving an Identity Score from 0-100%. More details
      provided mean more accurate results.
    </Text>
  );
};
