import React, { FC } from 'react';
import { JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import { AAMVACaption } from './AAMVACaption';
import { AAMVACompletedResult } from './AAMVACompletedResult';
import { AAMVAJobResult } from 'models/jobs/jobs';

interface AAMVADropdownsProps {
  readonly aamvaJobResult?: AAMVAJobResult;
  readonly country?: string;
  readonly jobSid: string;
  readonly jobStatus: JobStatus;
}

export const AAMVADropdowns: FC<AAMVADropdownsProps> = ({
  aamvaJobResult,
  country,
  jobSid,
  jobStatus
}) => {
  if (!aamvaJobResult) {
    return (
      <Dropdown title="DLV">
        <AAMVACaption dataUnavailable />
      </Dropdown>
    );
  }

  return (
    <AAMVACompletedResult
      aamvaJobResult={aamvaJobResult}
      country={country}
      jobSid={jobSid}
      jobStatus={jobStatus}
    />
  );
};
