import { Box } from '@vouched.id/vault';
import React, { FC, useMemo } from 'react';
import { JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import { DropdownItemsSection } from '../DropdownItemsSection';
import {
  addIconsToDropdownItems,
  BooleanDropdownItem,
  DropdownItemTypes,
  DropdownStatusIcon
} from '../utils';
import { DOBCaption } from './DOBCaption';
import { DOBRequestDataTable } from './DOBRequestDataTable';
import { DobRequestData } from 'components/account/jobs/job-modal/job-modal-tabs/components/dob/dob_model';

interface DOBCompletedResultProps {
  readonly jobStatus: JobStatus;
  readonly requestData?: DobRequestData;
  readonly dobIsMatch: boolean;
}

export const DOBCompletedResult: FC<DOBCompletedResultProps> = ({
  jobStatus,
  requestData,
  dobIsMatch
}) => {
  const { dropdownHasWarnings, dropdownHasRejections, dropdownItems } =
    useMemo(() => {
      const dropdownItemsWithoutIcons: BooleanDropdownItem[] = [
        {
          text: 'DOB Match',
          type: DropdownItemTypes.BooleanDropdownItem,
          value: dobIsMatch,
          available: true,
          hasErrorOrWarning: {
            hasError: !dobIsMatch,
            hasWarning: false
          }
        }
      ];

      return addIconsToDropdownItems(dropdownItemsWithoutIcons);
    }, [dobIsMatch]);

  return (
    <Dropdown
      statusIcon={
        <DropdownStatusIcon
          dropdownHasRejections={dropdownHasRejections}
          dropdownHasWarnings={dropdownHasWarnings}
          jobStatus={jobStatus}
        />
      }
      title="DOB"
    >
      <Box>
        <DOBCaption />
        <DropdownItemsSection dropdownItems={dropdownItems} />
        {requestData && <DOBRequestDataTable requestData={requestData} />}
      </Box>
    </Dropdown>
  );
};
