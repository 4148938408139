import { Box } from '@vouched.id/vault';
import React, { FC, useMemo } from 'react';
import { Tooltips } from '../../tooltips';
import { itemHasErrorOrWarning } from '../../utils';
import { Dropdown } from '../Dropdown';
import {
  addIconsToDropdownItems,
  BooleanDropdownItem,
  ConfidenceDropdownItem,
  DropdownItemTypes
} from '../utils';
import { ReviewCaption } from './ReviewCaption';
import { ReviewTable } from './ReviewTable';
import { JobError, JobUpdateReview, ResultConfidences } from 'models/jobs/jobs';

interface ReviewProps {
  readonly jobConfidences: ResultConfidences;
  readonly jobErrors: JobError[];
  readonly jobReview: JobUpdateReview | null;
  readonly jobSid: string;
  readonly setJobReviewSuccess: (success: boolean | null) => void;
}

export const ReviewDropdowns: FC<ReviewProps> = ({
  jobConfidences,
  jobErrors,
  jobReview,
  jobSid,
  setJobReviewSuccess
}) => {
  const idConfidence = jobConfidences.id;
  const selfieConfidence = jobConfidences.selfie;
  const faceMatchConfidence = jobConfidences.faceMatch;
  const nameMatchConfidence = jobConfidences.nameMatch;
  const birthDateMatchConfidence = jobConfidences.birthDateMatch;

  const { dropdownItems } = useMemo(() => {
    const dropdownItemsWithoutIcons: (
      | BooleanDropdownItem
      | ConfidenceDropdownItem
    )[] = [
      {
        text: 'Valid ID',
        type: DropdownItemTypes.ConfidenceDropdownItem,
        available: typeof idConfidence === 'number',
        hasErrorOrWarning: itemHasErrorOrWarning(
          jobErrors,
          'InvalidIdPhotoError'
        ),
        tooltip: Tooltips.ValidId,
        reviewLabel: 'idValid'
      },
      {
        text: 'Valid Selfie',
        type: DropdownItemTypes.ConfidenceDropdownItem,
        available: typeof selfieConfidence === 'number',
        hasErrorOrWarning: itemHasErrorOrWarning(
          jobErrors,
          'InvalidUserPhotoError'
        ),
        tooltip: Tooltips.ValidSelfie,
        reviewLabel: 'selfieValid'
      },
      {
        text: 'ID matches Selfie',
        type: DropdownItemTypes.ConfidenceDropdownItem,
        available: typeof faceMatchConfidence === 'number',
        hasErrorOrWarning: itemHasErrorOrWarning(jobErrors, 'FaceMatchError'),
        tooltip: Tooltips.IdMatchesSelfie,
        reviewLabel: 'faceMatch'
      },
      {
        text: 'ID matches Submitted Name',
        type: DropdownItemTypes.ConfidenceDropdownItem,
        available: typeof nameMatchConfidence === 'number',
        hasErrorOrWarning: itemHasErrorOrWarning(jobErrors, 'NameMatchError'),
        tooltip: Tooltips.IdMatchesSubmittedName,
        reviewLabel: 'nameMatch'
      },
      {
        text: 'ID matches Submitted DOB',
        type: DropdownItemTypes.ConfidenceDropdownItem,
        available: typeof birthDateMatchConfidence === 'number',
        hasErrorOrWarning: itemHasErrorOrWarning(
          jobErrors,
          'BirthDateMatchError'
        ),
        tooltip: Tooltips.IdMatchesSubmittedDOB,
        reviewLabel: 'birthDateMatch'
      }
    ];

    return addIconsToDropdownItems(dropdownItemsWithoutIcons);
  }, [
    jobErrors,
    itemHasErrorOrWarning,
    idConfidence,
    selfieConfidence,
    faceMatchConfidence,
    nameMatchConfidence,
    birthDateMatchConfidence
  ]);

  return (
    <Dropdown title="Review">
      <Box>
        <ReviewCaption />
        <ReviewTable
          dropdownItems={dropdownItems.filter(
            (item) => item.available && item.reviewLabel
          )}
          jobReview={jobReview}
          jobSid={jobSid}
          setJobReviewSuccess={setJobReviewSuccess}
        />
      </Box>
    </Dropdown>
  );
};
