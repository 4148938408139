import { Box, Text, ThemeContext } from '@vouched.id/vault';
import React, { FC, useContext } from 'react';
import { Tooltip } from './Tooltip';
import { useTableStyles } from './utils';

export interface MultiSpanTableEntry {
  title: string;
  items: { label: string; labelTooltip?: string; value?: number | string }[];
}

interface MultiSpanTableProps {
  readonly header: string;
  readonly entries: MultiSpanTableEntry[];
}

export const MultiSpanTable: FC<MultiSpanTableProps> = ({
  entries,
  header
}) => {
  const { tokens } = useContext(ThemeContext).theme;
  const { tableStyles, thStyles, tdStyles, trStyles } = useTableStyles();

  return (
    <table style={tableStyles}>
      <tbody>
        <tr>
          <th colSpan={3} style={{ ...thStyles, textAlign: 'center' }}>
            {header}
          </th>
        </tr>
        {entries.map((entry, entryIndex) => {
          return entry.items.map((item, itemIndex) => {
            return (
              <tr
                key={item.label}
                style={{
                  backgroundColor:
                    entryIndex % 2 === 0 ? trStyles.backgroundColor : undefined
                }}
              >
                {itemIndex === 0 && (
                  <td
                    rowSpan={entry.items.length}
                    style={{ ...tdStyles, padding: tokens.spacing.large }}
                  >
                    <Text
                      style={{
                        fontWeight: tokens.text.weight.bold,
                        textAlign: 'center'
                      }}
                    >
                      {entry.title}
                    </Text>
                  </td>
                )}
                <td style={tdStyles}>
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    style={{ gap: '5px' }}
                  >
                    <Text>{item.label}</Text>
                    {item.labelTooltip && (
                      <Box>
                        <Tooltip>{item.labelTooltip}</Tooltip>
                      </Box>
                    )}
                  </Box>
                </td>
                <td style={tdStyles}>
                  <Text>{item.value}</Text>
                </td>
              </tr>
            );
          });
        })}
      </tbody>
    </table>
  );
};
