import { Box, Text } from '@vouched.id/vault';
import React from 'react';
import { WarningIcon } from './Icons/Warning';
import { JobStatus } from './utils';

interface JobStatusLabelProps {
  readonly status: JobStatus;
  readonly hasWarnings: boolean;
}

const getStatusColor = (status: JobStatus): string => {
  switch (status) {
    case 'active':
      return '#0000CC';
    case 'removed':
      return '#222222';
    case 'approved':
      return '#00CC00';
    case 'rejected':
      return '#CC0000';
  }
};

export const JobStatusLabel: React.FC<JobStatusLabelProps> = ({
  status,
  hasWarnings
}) => {
  return (
    <Box
      display="flex"
      style={{
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        display="flex"
        style={{
          backgroundColor: getStatusColor(status),
          height: '30px',
          borderRadius: '30px',
          padding: '0px 20px',
          justifyContent: 'center',
          alignItems: 'center',
          textTransform: 'capitalize'
        }}
      >
        <Text colorVariation="inversePrimary">{status}</Text>
      </Box>
      {hasWarnings && status === 'approved' && <WarningIcon size={30} />}
    </Box>
  );
};
