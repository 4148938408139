import { Text } from '@vouched.id/vault';
import React, { FC } from 'react';

export const LocationCaption: FC = () => {
  return (
    <Text style={{ marginBottom: '15px' }}>
      A location comparison between the ID Document's address and the submission
      device's IP address.
    </Text>
  );
};
