import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  TextField
} from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import cx from 'classnames';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState
} from 'react';
import { GeoData, GeoLookupSuggestion } from 'react-geolookup-v2';
import {
  CompanyAccount,
  CompanySize
} from '../../models/company-account/company-account.model';
import { User } from '../../models/user/user.model';
import { ReusableSelect } from '../reusable/select';
import { GeoLocator } from './geolocator';
import { ButtonWithLoader } from 'components/reusable/button-loader';
import { setAccount, setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { updateAccountInfo, updateAccountTerms } from 'store/sagas/sagas';
import { history } from 'utils/history';
import { removeSpace } from 'utils/ui.utils';
import './update-account-info.css';

const CURRENT_TERMS_VERSION = '1.3';

export const UpdateAccountInfo: React.FC = () => {
  const { state, dispatch, asyncDispatch } = useGlobalState();
  const stripe = useStripe();
  const elements = useElements();

  type InputEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  const account = {
    ...state.account
  } as CompanyAccount;

  const user = {
    ...state.user
  } as User;

  const [country, setCountry] = useState<string | null>(null);
  const [street, setStreet] = useState<string | null>(null);
  const [postcode, setPostcode] = useState<string | null>(null);
  const [province, setProvince] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);

  const [companyName, setCompanyName] = useState('');
  const [companySize, setCompanySize] = useState<CompanySize | ''>('');
  const handleCompanySize = useCallback(
    (e: ChangeEvent<{ value: CompanySize | '' }>) => {
      setCompanySize(e.target.value);
    },
    []
  );

  const [companyIndustry, setCompanyIndustry] = useState('');
  const handleCompanyIndustry = useCallback(
    (e: ChangeEvent<{ value: string }>) => {
      setCompanyIndustry(e.target.value);
    },
    []
  );

  const changingDisabled = !user.emailVerified || !user.isAccountAdmin;

  const [invalidLocation, setInvalidLocation] = useState(false);
  const [invalidData, setInvalidData] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [collapsibleOpen, setCollapsibleOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsVersion, setTermsVersion] = useState('');

  useEffect(() => {
    const getInitialCount = () => {
      const count = account.setupAccountStep ?? '';
      switch (count) {
        case 'COMPANY':
          return 1;
        case 'TERMS':
          return 2;
        case 'BILLING':
          return 3;
        default:
          return 0;
      }
    };

    setCount(getInitialCount());
  }, [account.setupAccountStep]);

  const [count, setCount] = useState(0);

  const handleChangeFirstName = useCallback((e: InputEvent) => {
    setInvalidData(false);
    setFirstName(e.target.value);
  }, []);

  const handleChangeLastName = useCallback((e: InputEvent) => {
    setInvalidData(false);
    setLastName(e.target.value);
  }, []);

  const handleChangePhone = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
      /*
       * Cursed knowledge: The `material-ui-phone-number` package ships bad types, this will never be an event,
       * this is just to make the compiler happy :)
       */
      if (typeof e !== 'string') {
        return;
      }

      setInvalidData(false);
      setPhone(e);
    },
    []
  );

  const handleChangeCompanyName = useCallback((e: InputEvent) => {
    setInvalidData(false);
    setCompanyName(e.target.value);
  }, []);

  const handleChangeCompanyWebsite = useCallback((e: InputEvent) => {
    setInvalidData(false);
    setCompanyWebsite(e.target.value);
  }, []);

  const handleChangeCity = useCallback((e: InputEvent) => {
    setInvalidData(false);
    setCity(e.target.value);
  }, []);

  const handleChangeStreet = useCallback((e: InputEvent) => {
    setInvalidData(false);
    setStreet(e.target.value);
  }, []);

  const handleChangeProvince = useCallback((e: InputEvent) => {
    setInvalidData(false);
    setProvince(e.target.value);
  }, []);

  const handleChangeCountry = useCallback((e: InputEvent) => {
    setInvalidData(false);
    setCountry(e.target.value);
  }, []);

  const handlePostalCode = useCallback((e: InputEvent) => {
    setInvalidData(false);
    setPostcode(removeSpace(e.target.value));
  }, []);

  const [companyWebsite, setCompanyWebsite] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onGeocodeSuggest = (suggest?: GeoLookupSuggestion): GeoData => {
    if (!suggest) {
      return {};
    }

    setInvalidLocation(false);
    const geocoded: GeoData = {};

    if (suggest.raw.address.village) {
      geocoded.city = suggest.raw.address.village;
    } else if (suggest.raw.address.hamlet) {
      geocoded.city = suggest.raw.address.hamlet;
    } else if (suggest.raw.address.city) {
      geocoded.city = suggest.raw.address.city;
    }

    if (suggest.raw.address.state) {
      geocoded.state = suggest.raw.address.state;
    }

    geocoded.location = {
      lat: suggest.raw.lat || 0,
      lon: suggest.raw.lon || 0
    };

    geocoded.placeId = suggest.placeId;
    geocoded.isFixture = suggest.isFixture;
    geocoded.label = suggest.raw.display_name ?? '';
    geocoded.postcode = suggest.raw.address.postcode || '';

    geocoded.country = suggest.raw.address.country;
    geocoded.postcode = suggest.raw.address.postcode || '';

    if (geocoded.city) {
      setCity(geocoded.city);
    } else {
      setCity('');
    }

    if (geocoded.state) {
      setProvince(geocoded.state);
    } else {
      setProvince('');
    }

    if (geocoded.country) {
      setCountry(geocoded.country);
    } else {
      setCountry('');
    }

    if (geocoded.postcode) {
      setPostcode(removeSpace(geocoded.postcode));
    } else {
      setPostcode('');
    }

    return geocoded;
  };

  const companyInfoPost = () => {
    if (!city || !province || !country || !postcode) {
      setInvalidLocation(true);
      setInvalidData(true);
      return;
    }

    if (
      companyName === '' ||
      companyIndustry === '' ||
      !companySize ||
      companyWebsite === ''
    ) {
      setInvalidData(true);
      return;
    }

    setIsLoading(true);

    void asyncDispatch(
      updateAccountInfo({
        name: companyName,
        url: companyWebsite,
        industry: companyIndustry,
        companySize,
        setupAccountStep: 'TERMS',
        country,
        province,
        city,
        street,
        postalCode: postcode
      })
    )
      .then((data) => {
        dispatch(
          setMessages({
            value: 'Information updated successfully',
            severity: 'success'
          })
        );

        dispatch(setAccount(data.updatedAccount));
        setInvalidData(false);
        setCount(count + 1);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const termsPost = () => {
    if (!termsAccepted || !termsVersion) {
      setInvalidData(true);
      dispatch(
        setMessages({
          value:
            'Please accept the terms and conditions by selecting the checkbox below the terms and conditions',
          severity: 'error'
        })
      );

      return;
    }

    setIsLoading(true);

    void asyncDispatch(
      updateAccountTerms({
        setupAccountStep: 'BILLING',
        termsVersion
      })
    )
      .then((data) => {
        dispatch(
          setMessages({
            value: 'Terms acceptance saved!',
            severity: 'success'
          })
        );

        dispatch(setAccount(data.updatedAccount));
        setInvalidData(false);
        setCount(count + 1);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const contactInfoPost = () => {
    if (firstName === '' || lastName === '' || phone === '') {
      setInvalidData(true);
      return;
    }

    setIsLoading(true);
    asyncDispatch(
      updateAccountInfo({
        firstName,
        lastName,
        phoneNumber: phone,
        setupAccountStep: 'COMPANY'
      })
    )
      .then((data) => {
        dispatch(setAccount(data.updatedAccount));
        setIsLoading(false);
        dispatch(
          setMessages({
            value: 'Information updated successfully',
            severity: 'success'
          })
        );

        setCount(count + 1);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const saveAndContinue = (step: number) => {
    switch (step) {
      case 0:
        contactInfoPost();
        break;
      case 1:
        companyInfoPost();
        break;
      case 2:
        termsPost();
        break;
      case 3:
        break;
    }
  };

  const rejectTerms = () => {
    dispatch(
      setMessages({
        value:
          'Thank you for your interest in Vouched.  Please accept our Terms of Service to use the Vouched Services.  Otherwise, please contact our sales team to get set-up with Vouched by emailing hello@vouched.id',
        severity: 'info'
      })
    );
  };

  const submit = (event: FormEvent) => {
    setIsLoading(true);
    event.preventDefault();

    if (!elements || !stripe) {
      setIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setIsLoading(false);
      return;
    }

    stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement
      })
      .then(async ({ error, paymentMethod }) => {
        if (error) {
          console.error(error);
          const message =
            error.type === 'validation_error'
              ? (error.message ?? 'n/a')
              : 'Error validating your card information.';

          dispatch(
            setMessages({
              value: message,
              severity: 'error'
            })
          );

          setIsLoading(false);
          return;
        }

        try {
          const data = await asyncDispatch(
            updateAccountInfo({
              paymentMethodId: paymentMethod.id
            })
          );

          dispatch(setAccount(data.updatedAccount));
          history.push('/account/get-started');
          setIsLoading(false);
        } catch (error_) {
          console.error(error_);
          setIsLoading(false);
          setInvalidData(true);
        }
      })
      .catch(console.error);
  };

  const toggleCollapsible = () => {
    setCollapsibleOpen(!collapsibleOpen);
  };

  return (
    <div className="update-account-main">
      <h1>Set up your account</h1>
      <div className="update-account-tabs">
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: 'solid',
            borderWidth: count === 0 ? '4px' : '2px',
            borderColor: count === 0 ? '#635BFF' : 'inherit',
            padding: '16px'
          }}
        >
          <span
            style={{
              fontSize: '16px',
              color: count === 0 ? '#635BFF' : 'inherit',
              lineHeight: '18px',
              fontWeight: 600
            }}
          >
            Step 1: Contact Info
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: 'solid',
            borderWidth: count === 1 ? '4px' : '2px',
            borderColor: count === 1 ? '#635BFF' : 'inherit',
            padding: '16px'
          }}
        >
          <span
            style={{
              fontSize: '16px',
              color: count === 1 ? '#635BFF' : 'inherit',
              lineHeight: '18px',
              fontWeight: 600
            }}
          >
            Step 2: Company Info
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: 'solid',
            borderWidth: count === 2 ? '4px' : '2px',
            borderColor: count === 2 ? '#635BFF' : 'inherit',
            padding: '16px'
          }}
        >
          <span
            style={{
              fontSize: '16px',
              color: count === 2 ? '#635BFF' : 'inherit',
              lineHeight: '18px',
              fontWeight: 600
            }}
          >
            Step 3: Accept Terms and Conditions
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: 'solid',
            borderWidth: count === 2 ? '4px' : '2px',
            borderColor: count === 2 ? '#635BFF' : 'inherit',
            padding: '16px'
          }}
        >
          <span
            style={{
              fontSize: '16px',
              color: count === 3 ? '#635BFF' : 'inherit',
              lineHeight: '18px',
              fontWeight: 600
            }}
          >
            Step 4: Billing Info
          </span>
        </div>
      </div>
      {count === 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%'
          }}
        >
          <h2>Contact Info</h2>
          <span
            style={{
              fontSize: '16px',
              color: '#444444',
              lineHeight: '24px',
              fontWeight: 500,
              marginBottom: '3vh'
            }}
          >
            To complete the sign up process we need to verify your contact
            information.
          </span>
          <span
            style={{
              color: '#999999',
              fontSize: '16px',
              fontWeight: 800,
              lineHeight: '24px',
              marginBottom: '2vh'
            }}
          >
            Contact Information
          </span>
          <TextField
            className="g-input-height u-margin-bottom-medium"
            error={firstName === '' && invalidData}
            helperText={firstName ? '' : 'Invalid First name.'}
            onChange={handleChangeFirstName}
            placeholder="First name"
            required
          />
          <TextField
            className="g-input-height u-margin-bottom-medium"
            error={lastName === '' && invalidData}
            helperText={lastName ? '' : 'Invalid Last name.'}
            onChange={handleChangeLastName}
            placeholder="Last name"
            required
          />
          <MuiPhoneNumber defaultCountry="us" onChange={handleChangePhone} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '1vh'
            }}
          >
            <Button
              onClick={() => {
                saveAndContinue(0);
              }}
            >
              Save and continue
            </Button>
          </div>
        </div>
      )}
      {count === 1 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%'
          }}
        >
          <h2>Company Info</h2>
          <span
            style={{
              fontSize: '16px',
              color: '#444444',
              lineHeight: '24px',
              fontWeight: 500,
              marginBottom: '3vh'
            }}
          >
            Help us understand what you do.
          </span>
          <span
            style={{
              color: '#999999',
              fontSize: '16px',
              fontWeight: 800,
              lineHeight: '24px',
              marginBottom: '2vh'
            }}
          >
            Company Information
          </span>
          <TextField
            className="g-input-height u-margin-bottom-medium"
            error={companyName === '' && invalidData}
            helperText={companyName ? '' : 'Invalid Company name.'}
            onChange={handleChangeCompanyName}
            placeholder="Company name"
            required
          />
          <TextField
            className="g-input-height u-margin-bottom-medium"
            error={companyWebsite === '' && invalidData}
            helperText={companyWebsite ? '' : 'Invalid Company Website.'}
            onChange={handleChangeCompanyWebsite}
            placeholder="Company website"
            required
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginBottom: '18px'
            }}
          >
            {companyIndustry === '' && (
              <span
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  fontSize: '0.8125rem',
                  color: '#7f90a0'
                }}
              >
                Industry
              </span>
            )}
            <ReusableSelect
              className="u-margin-bottom-small"
              items={[
                { name: 'Accounting', id: 'Accounting' },
                {
                  name: 'Administration & Office Support',
                  id: 'Administration & Office Support'
                },
                {
                  name: 'Advertising, Arts & Media',
                  id: 'Advertising, Arts & Media'
                },
                {
                  name: 'Banking & Financial Services',
                  id: 'Banking & Financial Services'
                },
                {
                  name: 'Call Centre & Customer Service',
                  id: 'Call Centre & Customer Service'
                },
                {
                  name: 'Community Services & Development',
                  id: 'Community Services & Development'
                },
                { name: 'Construction', id: 'Construction' },
                {
                  name: 'Consulting & Strategy',
                  id: 'Consulting & Strategy'
                },
                {
                  name: 'Design & Architecture',
                  id: 'Design & Architecture'
                },
                { name: 'Education & Training', id: 'Education & Training' },
                { name: 'Engineering', id: 'Engineering' },
                {
                  name: 'Farming, Animals & Conservation',
                  id: 'Farming, Animals & Conservation'
                },
                { name: 'Government & Defence', id: 'Government & Defence' },
                { name: 'Healthcare & Medical', id: 'Healthcare & Medical' },
                {
                  name: 'Hospitality & Tourism',
                  id: 'Hospitality & Tourism'
                },
                {
                  name: 'Human Resources & Recruitment',
                  id: 'Human Resources & Recruitment'
                },
                {
                  name: 'Information & Communication Technology',
                  id: 'Information & Communication Technology'
                },
                {
                  name: 'Insurance & Superannuation',
                  id: 'Insurance & Superannuation'
                },
                { name: 'Legal', id: 'Legal' },
                {
                  name: 'Manufacturing, Transport & Logistics',
                  id: 'Manufacturing, Transport & Logistics'
                },
                {
                  name: 'Marketing & Communications',
                  id: 'Marketing & Communications'
                },
                {
                  name: 'Mining, Resources & Energy',
                  id: 'Mining, Resources & Energy'
                },
                {
                  name: 'Real Estate & Property',
                  id: 'Real Estate & Property'
                },
                {
                  name: 'Retail & Consumer Products',
                  id: 'Retail & Consumer Products'
                },
                { name: 'Sales', id: 'Sales' },
                { name: 'Science & Technology', id: 'Science & Technology' },
                { name: 'Sport & Recreation', id: 'Sport & Recreation' },
                { name: 'Services', id: 'Services' }
              ]}
              onChange={handleCompanyIndustry}
              placeholder="Accounting"
              style={{
                width: '100%',
                borderBottom:
                  companyIndustry === '' && invalidData
                    ? '2px solid red'
                    : '#aaaaaa '
              }}
              value={companyIndustry}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginBottom: '18px'
            }}
          >
            {!companySize && (
              <span
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  fontSize: '0.8125rem',
                  color: '#7f90a0'
                }}
              >
                Number of employees
              </span>
            )}
            <ReusableSelect<CompanySize | ''>
              className="u-margin-bottom-small"
              defaultValue={state.account?.companySize ?? '0-10'}
              disabled={changingDisabled}
              items={[
                { id: '0-10', name: '0 - 10' },
                { id: '10-100', name: '10 - 100' },
                { id: '+100', name: 'more than 100' }
              ]}
              onChange={handleCompanySize}
              placeholder="Industry"
              style={{
                borderBottom:
                  !companySize && invalidData ? '2px solid red' : '#aaaaaa '
              }}
              value={companySize}
            />
          </div>
          <span className="geo-locate-label">
            Search for your city and select an entry from the dropdown that
            matches it or enter your organization's city, state, and country
            manually in the fields below
          </span>
          <GeoLocator error={invalidLocation} onSelect={onGeocodeSuggest} />
          <div style={{ marginBottom: '2vh' }} />
          <Grid item xs={12}>
            <TextField
              className="g-input-height u-margin-bottom-medium location-field"
              error={invalidData}
              onChange={handleChangeStreet}
              placeholder="Street"
              value={street}
            />
            <TextField
              className="g-input-height u-margin-bottom-medium location-field"
              error={city === '' && invalidData}
              helperText={city === null ? 'Invalid City' : ''}
              onChange={handleChangeCity}
              placeholder="City"
              required
              value={city}
            />
            <TextField
              className="g-input-height u-margin-bottom-medium location-field"
              error={province === '' && invalidData}
              helperText={province === null ? 'Invalid State/Province' : ''}
              onChange={handleChangeProvince}
              placeholder="State/Province"
              required
              value={province}
            />
            <TextField
              className="g-input-height u-margin-bottom-medium location-field"
              error={country === '' && invalidData}
              helperText={country === null ? 'Invalid Country' : ''}
              onChange={handleChangeCountry}
              placeholder="Country"
              required
              value={country}
            />
            <TextField
              className="g-input-height u-margin-bottom-medium location-field"
              error={postcode === '' && invalidData}
              helperText={postcode === null ? 'Invalid Postal Code' : ''}
              onChange={handlePostalCode}
              placeholder="Postal Code"
              required
              value={postcode}
            />
          </Grid>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <Button
              onClick={() => {
                saveAndContinue(1);
              }}
            >
              Save and continue
            </Button>
          </div>
        </div>
      )}
      {count === 2 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%'
          }}
        >
          <div
            style={{
              marginBottom: '50px'
            }}
          >
            Here are the{' '}
            <a
              href="https://www.vouched.id/company/legal/terms-of-service"
              rel="noreferrer"
              style={{
                fontWeight: '600',
                color: '#635BFF'
              }}
              target="_blank"
            >
              terms and conditions
            </a>{' '}
            for using the Vouched verification service. Please accept them
            before using Vouched
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  aria-label="Sample"
                  aria-labelledby="Hello"
                  checked={termsAccepted}
                  onChange={() => {
                    setTermsAccepted(!termsAccepted);
                    setTermsVersion(CURRENT_TERMS_VERSION);
                  }}
                  size="medium"
                />
              }
              label={
                <span>
                  By checking this box, I acknowledge that have read and
                  <br />
                  consent to the terms and conditions put forward by Vouched
                </span>
              }
              style={{
                marginBottom: '50px'
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Button
              onClick={() => {
                saveAndContinue(2);
              }}
              style={{ margin: '8px' }}
            >
              Accept Terms and Conditions
            </Button>
            <Button onClick={rejectTerms} style={{ margin: '8px' }}>
              Reject Terms and Conditions
            </Button>
          </div>
        </div>
      )}
      {count === 3 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%'
          }}
        >
          <h2>Billing Info</h2>
          <span
            style={{
              fontSize: '16px',
              color: '#444444',
              lineHeight: '24px',
              fontWeight: 500,
              marginBottom: '3vh'
            }}
          >
            When you click "Finish Account Setup", a 14 day free trial will
            begin on your account. You will not be charged until the end of the
            free trial
          </span>
          {(!state.account?.subscriptionTier ||
            state.account.subscriptionTier === 'QSRNDHPfV') && (
            <div
              style={{
                background: 'white',
                borderRadius: '5px',
                padding: '15px',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '2vh'
              }}
            >
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '24px',
                    color: '#444444',
                    lineHeight: '28px'
                  }}
                >
                  Base subscription
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '24px',
                    color: collapsibleOpen ? '#999999' : 'inherit',
                    lineHeight: '28px'
                  }}
                >
                  $300/month
                </span>
                <div onClick={toggleCollapsible}>
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: '16px',
                      color: '#999999',
                      lineHeight: '24px'
                    }}
                  >
                    What's included?
                  </span>
                  <i
                    className={
                      collapsibleOpen
                        ? cx('fa fa-angle-up')
                        : cx('fas fa-angle-down')
                    }
                    style={{
                      color: '#999999',
                      fontSize: '15px',
                      padding: '0px 5px'
                    }}
                  />
                </div>
              </div>
              <Collapse in={collapsibleOpen}>
                <div style={{ marginTop: '1vh' }}>
                  <div style={{ marginBottom: '2vh' }}>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: '#444444'
                      }}
                    >
                      Verify and onboard new customers, patients, partners and
                      gig contractors in seconds. With Vouched ID verification,
                      you can expand your reach while keeping the bad guys out.
                    </span>
                  </div>
                  <div
                    style={{
                      padding: '10px 0px',
                      borderTop: 'solid 1px #eeeeee'
                    }}
                  />
                  <div>
                    <div style={{ marginBottom: '2vh' }}>
                      <span
                        style={{
                          fontWeight: 800,
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#999999'
                        }}
                      >
                        Included features
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            color: '#444444'
                          }}
                        >
                          Expert ID Document Examination
                        </div>
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            color: '#444444'
                          }}
                        >
                          Facial Recognition, Liveness Detection
                        </div>
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            color: '#444444'
                          }}
                        >
                          Data Extraction
                        </div>
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            color: '#444444'
                          }}
                        >
                          Dashboard Access
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            color: '#444444'
                          }}
                        >
                          Anonymous Activity
                        </div>
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            color: '#444444'
                          }}
                        >
                          Analysis of email, phone, address, name
                        </div>
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            color: '#444444'
                          }}
                        >
                          Email age and fraud risk
                        </div>
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            color: '#444444'
                          }}
                        >
                          Phone source
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          )}

          <div>
            <div
              className="g-page-without-header-footer"
              style={{
                display: 'flex'
              }}
            >
              <div className="u-align-horizontal-center">
                <form
                  autoComplete="off"
                  className="g-form"
                  noValidate
                  onSubmit={submit}
                >
                  <form>
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                              color: '#aab7c4'
                            }
                          },
                          invalid: {
                            color: '#9e2146'
                          }
                        }
                      }}
                    />
                  </form>
                  <div>
                    <div className="subscription-confirm-container">
                      <div>
                        By clicking "Finish Account Setup" you will start your
                        subscription
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center'
                    }}
                  >
                    <ButtonWithLoader
                      disabled={isLoading}
                      isLoading={isLoading}
                      name="Finish Account Setup"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
