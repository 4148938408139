import { Box, Icons, Text } from '@vouched.id/vault';
import React, { FC, useEffect, useState } from 'react';
import { Dropdown } from '../../Dropdown';
import { SSNCaption } from '../SSNCaption';
import { ECBSVCompletedResult } from './ECBSVCompletedResult';
import { ECBSVRequestDataTable } from './ECBSVRequestDataTable';
import { SSNJob } from 'components/account/jobs/job-modal/job-modal-tabs/components/ssn/ssn_model';
import { JobType } from 'components/account/jobs/job_type';
import { JobStatus } from 'components/account/jobs-v2/utils';
import { useGlobalState } from 'store/reducers/reducer';
import { getSSNJob } from 'store/sagas/sagas';

interface ECBSVDropdownsProps {
  readonly jobSid: string;
  readonly jobStatus: JobStatus;
}

export const ECBSVDropdowns: FC<ECBSVDropdownsProps> = ({
  jobSid,
  jobStatus
}) => {
  const { asyncDispatch } = useGlobalState();
  const [eCBSVJob, setECBSVJob] = useState<SSNJob | null>(null);

  useEffect(() => {
    asyncDispatch(getSSNJob(jobSid)).then((res) => {
      setECBSVJob(res[0] ?? null);
    }, console.error);
  }, [jobSid]);

  if (!eCBSVJob) return <Icons.Loading />;

  const [request_data] = eCBSVJob.request_data.cvsRequestList;
  const response_data =
    eCBSVJob.response_data?.cvsResponseData?.data.cvsResponseList[0];

  const verificationCode = response_data?.verificationCode;

  if (!verificationCode) {
    return (
      <Dropdown title="SSN">
        <Box>
          <SSNCaption type={JobType.ecbsvSSN} />
          <Text>
            SSN verification could not be completed. Please try again or contact
            support.
          </Text>
          <Box
            display="flex"
            marginTop="spacing.default"
            style={{ gap: '15px' }}
          >
            <Text>Source:</Text>
            <Text style={{ fontWeight: '600' }}>eCBSV</Text>
          </Box>
          {request_data && <ECBSVRequestDataTable requestData={request_data} />}
        </Box>
      </Dropdown>
    );
  }

  let deathIndicator = '';

  switch (response_data.verificationData?.deathIndicator) {
    case undefined:
      deathIndicator = 'No Result';
      break;
    case 'Y':
      deathIndicator = 'Deceased';
      break;
    case 'N':
      deathIndicator = 'Not Deceased';
  }

  return (
    <ECBSVCompletedResult
      deathIndicator={deathIndicator}
      jobStatus={jobStatus}
      requestData={request_data}
      verified={verificationCode === 'Y'}
    />
  );
};
