import React, { FC } from 'react';
import { MultiSpanTable, MultiSpanTableEntry } from '../MultiSpanTable';
import { readableBoolean, readableNumber, readableString } from '../utils';
import {
  CrossCheckAddressResult,
  CrossCheckEkataIpResult,
  CrossCheckEmailResult,
  CrossCheckPhoneResult
} from 'models/jobs/crosscheck';
import { cleanArray } from 'utils/jobs';

export interface CrossCheckSubmittedData {
  firstName?: string;
  lastName?: string;
  address?: string;
  phone?: string;
  email?: string;
  ipAddress?: string;
}

interface CrossCheckDetailsTablesProps {
  readonly address: CrossCheckAddressResult | null;
  readonly phone: CrossCheckPhoneResult | null;
  readonly email: CrossCheckEmailResult | null;
  readonly ip: CrossCheckEkataIpResult | null;
  readonly submittedData: CrossCheckSubmittedData;
}

export const CrossCheckDetailsTables: FC<CrossCheckDetailsTablesProps> = ({
  address,
  phone,
  email,
  ip,
  submittedData
}) => {
  const ipDistanceStr = readableNumber(ip?.distance);

  const entries: MultiSpanTableEntry[] = [
    {
      title: 'Verified Fields',
      items: [
        {
          label: 'First Name',
          value: submittedData.firstName
        },
        {
          label: 'Last Name',
          value: submittedData.lastName
        },
        {
          label: 'Address',
          value: submittedData.address
        },
        {
          label: 'Phone',
          value: submittedData.phone
        },
        {
          label: 'Email',
          value: submittedData.email
        },
        {
          label: 'IP',
          value: submittedData.ipAddress
        }
      ]
    },
    {
      title: 'Address',
      items: [
        {
          label: 'Valid',
          value: readableBoolean(address?.isValid)
        },
        {
          label: 'Match',
          value: readableBoolean(address?.isMatch)
        },
        {
          label: 'Errors',
          value: cleanArray(address?.errors, 'error').join(', ')
        },
        {
          label: 'Warnings',
          value: cleanArray(address?.warnings, 'warnings').join(', ')
        }
      ]
    },
    {
      title: 'Phone',
      items: [
        {
          label: 'Valid',
          value: readableBoolean(phone?.isValid)
        },
        {
          label: 'Match',
          value: readableBoolean(phone?.isMatch)
        },
        {
          label: 'Type',
          value: readableString(phone?.type)
        },
        {
          label: 'Carrier',
          value: readableString(phone?.carrier)
        },
        {
          label: 'Last Seen Days',
          value: readableNumber(phone?.lastSeenDays)
        },
        {
          label: 'Errors',
          value: cleanArray(phone?.errors, 'error').join(', ')
        },
        {
          label: 'Warnings',
          value: cleanArray(phone?.warnings, 'warnings').join(', ')
        }
      ]
    },
    {
      title: 'Email',
      items: [
        {
          label: 'Valid',
          value: readableBoolean(email?.isValid)
        },
        {
          label: 'Match',
          value: readableBoolean(email?.isMatch)
        },
        {
          label: 'Email Domain Creation Date',
          value: readableString(email?.emailDomainCreationDate)
        },
        {
          label: 'Days First Seen',
          value: readableNumber(email?.daysFirstSeen)
        },
        {
          label: 'Errors',
          value: cleanArray(email?.errors, 'error').join(', ')
        },
        {
          label: 'Warnings',
          value: cleanArray(email?.warnings, 'warnings').join(', ')
        }
      ]
    },
    {
      title: 'IP',
      items: [
        {
          label: 'Valid',
          value: readableBoolean(ip?.isValid)
        },
        {
          label: 'Distance',
          value: ipDistanceStr === '' ? ipDistanceStr : `${ipDistanceStr} Miles`
        }
      ]
    }
  ];

  return <MultiSpanTable entries={entries} header="Details" />;
};
