import { Box, Text, ThemeContext } from '@vouched.id/vault';
import React, { Fragment, ReactElement, useContext } from 'react';
import { Tooltip } from './Tooltip';
import { JobUpdateReview } from 'models/jobs/jobs';

export interface DropdownItem {
  readonly available: boolean;
  readonly text: string;
  readonly statusIcon: ReactElement;
  readonly tooltip?: string;
  readonly submittedData?: string;
  readonly reviewLabel?: keyof JobUpdateReview;
}

interface DropdownItemsSectionProps {
  readonly dropdownItems: DropdownItem[];
}

export const DropdownItemsSection: React.FC<DropdownItemsSectionProps> = ({
  dropdownItems
}) => {
  const { tokens } = useContext(ThemeContext).theme;

  return (
    <Box
      display="grid"
      flex="1"
      marginY="spacing.xlarge"
      style={{
        gridTemplateColumns: 'auto auto 1fr',
        gap: '20px',
        alignItems: 'center'
      }}
    >
      {dropdownItems.map((item) => (
        <Fragment key={item.text}>
          {item.statusIcon}
          <Text
            style={{
              ...(!item.available && { color: tokens.colors.textInverseMuted })
            }}
          >
            {item.text}
          </Text>
          {item.tooltip ? <Tooltip>{item.tooltip}</Tooltip> : <div />}
        </Fragment>
      ))}
    </Box>
  );
};
