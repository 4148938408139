import { Box } from '@vouched.id/vault';
import React, { FC, useMemo } from 'react';
import { Tooltips } from '../../tooltips';
import { itemHasErrorOrWarning, JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import { DropdownItemsSection } from '../DropdownItemsSection';
import {
  addIconsToDropdownItems,
  BooleanDropdownItem,
  ConfidenceDropdownItem,
  DropdownItemTypes,
  DropdownStatusIcon
} from '../utils';
import { JobError, JobResult } from 'models/jobs/jobs';

interface SelfieVerificationCompletedResultProps {
  readonly jobResult: JobResult;
  readonly jobErrors: JobError[];
  readonly jobStatus: JobStatus;
}

export const SelfieVerificationCompletedResult: FC<
  SelfieVerificationCompletedResultProps
> = ({ jobResult, jobErrors, jobStatus }) => {
  const selfieConfidence = jobResult.confidences?.selfie;
  const livenessPerformedConfidence = jobResult.confidences?.liveness;

  const { dropdownHasWarnings, dropdownHasRejections, dropdownItems } =
    useMemo(() => {
      const dropdownItemsWithoutIcons: (
        | BooleanDropdownItem
        | ConfidenceDropdownItem
      )[] = [
        {
          text: 'Valid Selfie',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          available: typeof selfieConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(
            jobErrors,
            'InvalidUserPhotoError'
          ),
          tooltip: Tooltips.ValidSelfie
        },
        {
          text: 'Selfie Liveness Performed',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          available: typeof livenessPerformedConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(
            jobErrors,
            'LivenessSkippedError'
          ),
          tooltip: Tooltips.SelfieLivenessPerformed
        }
      ];

      return addIconsToDropdownItems(dropdownItemsWithoutIcons);
    }, [
      jobErrors,
      itemHasErrorOrWarning,
      addIconsToDropdownItems,
      selfieConfidence,
      livenessPerformedConfidence
    ]);

  return (
    <Dropdown
      statusIcon={
        <DropdownStatusIcon
          dropdownHasRejections={dropdownHasRejections}
          dropdownHasWarnings={dropdownHasWarnings}
          jobStatus={jobStatus}
        />
      }
      title="Selfie Verification"
    >
      <Box>
        <DropdownItemsSection dropdownItems={dropdownItems} />
      </Box>
    </Dropdown>
  );
};
