import { CircularProgress } from '@material-ui/core';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdf';
import React, { useEffect, useState } from 'react';
import { setInvoices } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { getSubscriptInvoices } from 'store/sagas/sagas';
import './manage-subscription.css';
import { longFormatDate } from 'utils/longFormatDate';

// filter 'draft' prefixed invoices from being displayed
const EXCLUDED_INVOICE_PREFIXES = ['draft'];

export const InvoiceList: React.FC = () => {
  const { state, dispatch, asyncDispatch } = useGlobalState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!state.invoices) {
      setLoading(true);
      asyncDispatch(getSubscriptInvoices())
        .then((data) => {
          dispatch(setInvoices(data));
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <div>
      <h1>Invoices</h1>
      <div>
        {loading ? (
          <CircularProgress size="1.2rem" style={{ color: '#290e88' }} />
        ) : state.invoices ? (
          <div className="billing__container">
            <div className="billing__container-head">
              <span>Number</span>
              <span>Total</span>
              <span>Created</span>
              <span>View/Pay Invoice</span>
              <span>Download PDF</span>
            </div>
            <div className="billing__container-body">
              {state.invoices
                .filter(
                  (invoice) =>
                    !EXCLUDED_INVOICE_PREFIXES.some((term) =>
                      invoice.invoice_number
                        .toLowerCase()
                        .startsWith(term.toLowerCase())
                    )
                )
                .map((i) => {
                  const dollars = i.amount;

                  return (
                    <div key={i.id}>
                      <span>{i.invoice_number}</span>
                      <span>
                        {dollars.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD'
                        })}
                      </span>
                      <span>{longFormatDate(new Date(i.date))}</span>
                      <a href={i.invoice_payment_link}>
                        <span>View/Pay Invoice</span>
                      </a>
                      {i.invoice_pdf_link && (
                        <a href={i.invoice_pdf_link}>
                          <PictureAsPdfOutlinedIcon
                            style={{ color: '#4122af' }}
                          />
                        </a>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          <div>
            Your account does not currently have any past or outstanding
            invoices
          </div>
        )}
      </div>
    </div>
  );
};
