import { Box, Button, Input, Stack } from '@vouched.id/vault';
import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useState
} from 'react';
import { useParams } from 'react-router';
import { JobPageParams } from '../../utils';
import { Dropdown } from '../Dropdown';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { removeJob } from 'store/sagas/sagas';
import { history } from 'utils/history';

export const DeleteDropdown: FC = () => {
  const { asyncDispatch, dispatch } = useGlobalState();
  const { id: jobSid } = useParams<JobPageParams>();
  const [isLoading, setIsLoading] = useState(false);
  const [inputJobSid, setInputJobSid] = useState('');
  const [isInvalidInput, setIsInvalidInput] = useState(false);

  const handleRemove = (sid: string) => {
    asyncDispatch(removeJob(sid, false))
      .then(() => {
        dispatch(
          setMessages({
            value: `Deleted job ${sid}`,
            severity: 'success'
          })
        );

        history.replace('/account/jobs');
      })
      .catch(() => {
        dispatch(
          setMessages({
            value: `Unable to delete job ${sid}`,
            severity: 'error'
          })
        );

        setIsLoading(false);
      });
  };

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputJobSid(value);
    if (value === jobSid || value === '') setIsInvalidInput(false);
  }, []);

  const handleBlur = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsInvalidInput(e.target.value !== jobSid);
  }, []);

  const submit = (event: FormEvent) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    if (inputJobSid) {
      if (inputJobSid !== jobSid) {
        setIsInvalidInput(true);
        return;
      }

      setIsLoading(true);
      handleRemove(jobSid);
    }
  };

  return (
    <Dropdown title="Delete">
      <p>
        Warning! Once deleted, this job will be gone forever. Type in this job's
        ID, {jobSid}, to delete it.
      </p>
      <Box
        maxWidth="52rem"
        noValidate
        onSubmit={submit}
        tag="form"
        width="100%"
      >
        <Stack spacing="spacing.large">
          <Box>
            <Input
              autoComplete="off"
              inputMode="text"
              isInvalid={isInvalidInput}
              label="Job ID"
              name="Job ID"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              validationMessage="Job ID's do not match"
            />
          </Box>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            marginTop="spacing.default"
            paddingTop="spacing.default"
            width="100%"
          >
            <Button data-cy="submit" title={isLoading ? 'loading' : ''}>
              Delete
            </Button>
          </Box>
        </Stack>
      </Box>
    </Dropdown>
  );
};
