import React, { FC } from 'react';
import { MultiSpanTable } from '../MultiSpanTable';
import { readableBoolean } from '../utils';
import {
  AAMVARequestData,
  AAMVAResponseData
} from 'components/account/jobs/job-modal/job-modal-tabs/components/aamva/detail-aamva';
import { AAMVAJobResult } from 'models/jobs/jobs';
import { AAMVADetailsResult } from 'store/sagas/sagas';
import { roundNumber } from 'utils/ui.utils';

interface AAMVADetailsTableProps {
  readonly aamvaJobResult: AAMVAJobResult;
  readonly aamvaDetails: AAMVADetailsResult | null;
  readonly country?: string;
}

export const AAMVADetailsTable: FC<AAMVADetailsTableProps> = ({
  aamvaJobResult,
  aamvaDetails,
  country
}) => {
  let requestData: AAMVARequestData | undefined;
  if (aamvaDetails?.requestData) {
    try {
      requestData = JSON.parse(aamvaDetails.requestData) as AAMVARequestData;
    } catch {
      console.error('Unable to parse JSON');
    }
  }

  let responseData: AAMVAResponseData | undefined;
  if (aamvaDetails?.responseData) {
    try {
      responseData = JSON.parse(aamvaDetails.responseData) as AAMVAResponseData;
    } catch {
      console.error('Unable to parse JSON');
    }
  }

  let errors: string[] | undefined;
  if (aamvaDetails?.errors) {
    try {
      ({ errors } = JSON.parse(aamvaDetails.errors) as { errors: string[] });
    } catch {
      console.error('Unable to parse JSON');
    }
  }

  let warnings: string[] | undefined;
  if (aamvaDetails?.warnings) {
    try {
      ({ warnings } = JSON.parse(aamvaDetails.warnings) as {
        warnings: string[];
      });
    } catch {
      console.error('Unable to parse JSON');
    }
  }

  return (
    <MultiSpanTable
      entries={[
        {
          title: 'Request Data',
          items: [
            {
              label: 'License Number',
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              value: requestData?.licenceNumber || requestData?.licenseNumber
            },
            {
              label: 'Last Name',
              value: requestData?.lastName
            },
            {
              label: 'State',
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              value: requestData?.state || requestData?.province
            },
            {
              label: 'Date of Birth',
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              value: requestData?.dateOfBirth || requestData?.birthDate
            },
            ...(country === 'CA'
              ? [
                  {
                    label: 'First Name',
                    value: requestData?.firstName
                  }
                ]
              : []),
            {
              label: 'Issue Date',
              value: requestData?.issueDate
            },
            {
              label: 'Expiration Date',
              value: requestData?.expirationDate
            }
          ]
        },
        {
          title: 'Response Data',
          items: [
            ...(country === 'CA'
              ? [
                  {
                    label: 'Status',
                    value: responseData?.response.status
                  },
                  {
                    label: 'Status Code',
                    value: responseData?.response.statusCode
                  }
                ]
              : []),
            {
              label: 'License Number',
              value: readableBoolean(responseData?.licenseNumber)
            },
            {
              label: 'License Type',
              value: readableBoolean(responseData?.licenseType)
            },
            {
              label: 'Last Name',
              value: readableBoolean(responseData?.lastName)
            },
            {
              label: 'Last Name Fuzzy',
              value: readableBoolean(responseData?.lastNameFuzzy)
            },
            {
              label: 'Date of Birth',
              value: readableBoolean(responseData?.birthDate)
            },
            {
              label: 'Issue Date',
              value: readableBoolean(responseData?.issueDate)
            },
            {
              label: 'Expiration Date',
              value: readableBoolean(responseData?.expirationDate)
            },
            {
              label: 'State',
              value: readableBoolean(responseData?.state)
            }
          ]
        },
        {
          title: 'Other',
          items: [
            {
              label: 'Source',
              value: country === 'CA' ? 'CA DMV' : 'AAMVA'
            },
            {
              label: 'Confidence Score',
              value: roundNumber(aamvaJobResult.confidenceScore) ?? undefined
            },
            {
              label: 'Errors',
              value: errors ? errors.join(', ') : undefined
            },
            {
              label: 'Warnings',
              value: warnings ? warnings.join(', ') : undefined
            },
            {
              label: 'Created At',
              value: aamvaJobResult.createdAt
            },
            {
              label: 'Updated At',
              value: aamvaJobResult.updatedAt
            }
          ]
        }
      ]}
      header="Details"
    />
  );
};
