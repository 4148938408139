import { OnCopyProps } from 'react-json-view';
import { State } from 'components/reusable/tick/tick';
import {
  AAMVAJobResult,
  Job,
  JobError,
  JobRequestParameters,
  JobResult,
  JobUpdateReview
} from 'models/jobs/jobs';
import { User } from 'models/user/user.model';
import {
  ACCOUNT_ADMIN_PERMISSIONS,
  DOWNLOADER_PERMISSIONS,
  isAuthorized
} from 'security/authorization';
import { AMLData } from 'store/sagas/sagas';

export const toName = (parameters: JobRequestParameters): string => {
  const { firstName, lastName, middleName } = parameters;
  return toNameFull(firstName, middleName, lastName);
};

export const toNameFull = (
  firstName: string | null | undefined,
  middleName: string | null | undefined,
  lastName: string | null | undefined
): string => {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return firstName || lastName
    ? `${firstName ?? ''} ${middleName ?? ''} ${lastName ?? ''}`.trim()
    : '';
};

export const filterVal = <T extends object>(
  InsightValues: T[],
  category: keyof T
): string[] | T[keyof T] => {
  const val = InsightValues.find((obj) => obj[category]);
  return val ? val[category] : [''];
};

export const getAamvaSummaryStatus = (aamva: AAMVAJobResult): State => {
  if (aamva.status === 'Pending') {
    return 'active';
  }

  if (!!aamva.enabled || aamva.status === 'Not Applicable') {
    return 'notApplicable';
  }

  if (aamva.hasErrors) {
    return 'warning';
  }

  return 'approved';
};

export const getDlvSummaryStatus = (approval: string): State => {
  if (approval === 'Approved') {
    return 'approved';
  }

  return 'rejected';
};

export const getAmlStatus = (amlConfidence: number): State => {
  if (amlConfidence === 1) {
    return 'approved';
  }

  if (amlConfidence === 0.5) {
    return 'warning';
  }

  if (amlConfidence === 0) {
    return 'rejected';
  }

  return 'notApplicable';
};

export const getLivenessPerformedStatus = (
  livenesConfidence: number | undefined
): State => {
  if (livenesConfidence === 1) {
    return 'approved';
  }

  if (livenesConfidence === 0.5) {
    return 'warning';
  }

  if (livenesConfidence === 0) {
    return 'rejected';
  }

  return 'notApplicable';
};

export const getLivenessSessionScoreStatus = (
  sessionConfidence: number
): State => {
  if (sessionConfidence >= 0.5) {
    return 'approved';
  }

  return 'rejected';
};

export const getIPSummaryStatus = (result?: JobResult): State => {
  if (!result?.ipAddress) {
    return 'notApplicable';
  }

  if (result.ipAddress.warnings) {
    return 'warning';
  }

  return 'approved';
};

export const errorChecked = ({
  errors,
  type
}: {
  errors: JobError[];
  type: string;
}): JobError | undefined => {
  return errors.find((e) => e.type === type && !e.suggestion);
};

export const warningChecked = ({
  errors,
  type
}: {
  errors: JobError[];
  type: string;
}): JobError | undefined => {
  return errors.find(
    (e) =>
      e.type === type &&
      ((!!e.suggestion && type === 'ExpiredIdError') ||
        !!e.warnings ||
        !!e.warning)
  );
};

export const jobHasWarnings = (errors: JobError[]): boolean => {
  return errors.some(
    (err) => !!err.warning || !!err.warnings || !!err.suggestion
  );
};

/*
 * fill up the review from the job based on what's in the job
 * and what's in the review job
 */
export const fillJobReview = (
  job: Job,
  review: JobUpdateReview | null | undefined
): JobUpdateReview => {
  review ||= {
    idValid: null,
    selfieValid: null,
    faceMatch: null,
    nameMatch: null,
    birthDateMatch: null
  };

  if (
    job.result?.confidences?.id !== null &&
    (review.idValid === null || review.idValid === undefined)
  ) {
    review.idValid = !errorChecked({
      errors: job.errors,
      type: 'InvalidIdPhotoError'
    });
  }

  if (
    job.result?.confidences?.selfie !== null &&
    (review.selfieValid === null || review.selfieValid === undefined)
  ) {
    review.selfieValid = !errorChecked({
      errors: job.errors,
      type: 'InvalidUserPhotoError'
    });
  }

  if (
    job.result?.confidences?.faceMatch !== null &&
    (review.faceMatch === null || review.faceMatch === undefined)
  ) {
    review.faceMatch = !errorChecked({
      errors: job.errors,
      type: 'FaceMatchError'
    });
  }

  if (
    job.result?.confidences?.nameMatch !== null &&
    (review.nameMatch === null || review.nameMatch === undefined)
  ) {
    review.nameMatch = !errorChecked({
      errors: job.errors,
      type: 'NameMatchError'
    });
  }

  if (
    job.result?.confidences?.birthDateMatch !== null &&
    (review.birthDateMatch === null || review.birthDateMatch === undefined)
  ) {
    review.birthDateMatch = !errorChecked({
      errors: job.errors,
      type: 'BirthDateMatchError'
    });
  }

  return review;
};

// Job modal tabs utils functions
export const jobFinished = (job: Job): boolean =>
  !job.isTest && (job.status === 'completed' || job.status === 'removed');

export const extractedAddress = (s: string | null | undefined): string => {
  if (!s) {
    return 'Address could not be parsed';
  }

  return s;
};

export function a11yProps(index: number): {
  id: string;
  'aria-controls': string;
} {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

export const isOpen = (
  openedTab: number,
  tabs: string[],
  tab: string
): boolean => tabs[openedTab]?.toLowerCase() === tab.toLowerCase();

export const AuthorizationActions: Record<string, (user: User) => boolean> = {
  files: (user: User): boolean => isAuthorized(user, DOWNLOADER_PERMISSIONS),
  delete: (user: User): boolean => isAuthorized(user, ACCOUNT_ADMIN_PERMISSIONS)
};

export const FACE_AUTH_BLACKLIST_TABS = [
  'crosscheck',
  'dlv',
  'aml',
  'files',
  'survey',
  'id',
  'ssn'
];

export const tabPermission = (
  tab: string,
  job: Job,
  { readonly, debug }: { readonly: boolean | null; debug: boolean | null }
): boolean => {
  tab = tab.toLowerCase();

  if (tab === 'photos' && !job.secondaryPhotos) {
    return false;
  }

  if (readonly && (tab === 'files' || tab === 'delete')) {
    return false;
  }

  if (!debug && tab === 'debug') {
    return false;
  }

  if (tab === 'location' && !job.result?.ipAddress && !job.result?.idAddress) {
    return false;
  }

  return true;
};

/**
 * AML utils functions
 */

export const getAmlHitsCount = (amlData: AMLData): number => {
  const matches = amlData.hits?.matches;
  if (!matches) {
    return 0;
  }

  return Object.keys(matches).length;
};

export const getAmlMatchedTypes = (amlData: AMLData): string => {
  const matches = amlData.hits?.matches;
  if (!matches) {
    return 'None';
  }

  return Object.keys(matches)
    .map((a) => a.toUpperCase())
    .join(', ');
};

export const getAmlCountries = (matchlist: AMLData): string => {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return matchlist.hits?.countries?.join(', ') || 'None';
};

export const getAmlMatchlists = (matchlist: AMLData): string => {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return matchlist.hits?.aka?.join(', ') || 'None';
};

export const getAmlPoliticalPositions = (matchlist: AMLData): string => {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return matchlist.hits?.politicalPositions?.join(', ') || 'None';
};

/*
 * workaround due to https://linear.app/vouched/issue/VOU-3212
 * consider moving when https://github.com/microlinkhq/react-json-view/pull/37 is merged
 */
export const copyHandler = (copy: OnCopyProps): void => {
  navigator.clipboard
    .writeText(JSON.stringify(copy.src, null, '  '))
    .catch(console.error);
};
