import { Text } from '@vouched.id/vault';
import React, { FC } from 'react';
import { Dropdown } from '../Dropdown';
import { SingleSpanTable, SingleSpanTableItem } from '../SingleSpanTable';

interface ScoresDropdownProps {
  readonly id?: number;
  readonly selfie?: number;
  readonly faceMatch?: number;
  readonly expired?: number;
  readonly barcodeMatch?: number;
  readonly nameMatch?: number;
  readonly birthDateMatch?: number;
}

export const ScoresDropdown: FC<ScoresDropdownProps> = ({
  id,
  selfie,
  faceMatch,
  expired,
  barcodeMatch,
  nameMatch,
  birthDateMatch
}) => {
  const items: SingleSpanTableItem[] = [];
  if (id !== undefined) {
    items.push({
      label: 'ID',
      value: id,
      valueColor: id >= 0.9 ? 'green' : 'red'
    });
  }

  if (selfie !== undefined) {
    items.push({
      label: 'Selfie',
      value: selfie,
      valueColor: selfie >= 0.9 ? 'green' : 'red'
    });
  }

  if (faceMatch !== undefined) {
    items.push({
      label: 'Face Match',
      value: faceMatch,
      valueColor: faceMatch >= 0.9 ? 'green' : 'red'
    });
  }

  if (expired !== undefined) {
    items.push({
      label: 'Expired',
      value: expired,
      valueColor: expired === 1 ? 'green' : 'red'
    });
  }

  if (barcodeMatch !== undefined) {
    items.push({
      label: 'Barcode Match',
      value: barcodeMatch,
      valueColor: barcodeMatch >= 0.9 ? 'green' : 'red'
    });
  }

  if (nameMatch !== undefined) {
    items.push({
      label: 'Name Match',
      value: nameMatch,
      valueColor: nameMatch >= 0.9 ? 'green' : 'red'
    });
  }

  if (birthDateMatch !== undefined) {
    items.push({
      label: 'Birth Date Match',
      value: birthDateMatch,
      valueColor: birthDateMatch >= 0.9 ? 'green' : 'red'
    });
  }

  return (
    <Dropdown title="Scores">
      <Text>Numerical confidence scores of checks performed for this job.</Text>
      <SingleSpanTable items={items} numColumns={2} />
    </Dropdown>
  );
};
