import { Box, Text } from '@vouched.id/vault';
import React, { useMemo } from 'react';
import { Tooltips } from '../../tooltips';
import { itemHasErrorOrWarning, JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import { DropdownItemsSection } from '../DropdownItemsSection';
import {
  addIconsToDropdownItems,
  BooleanDropdownItem,
  ConfidenceDropdownItem,
  DropdownItemTypes,
  DropdownStatusIcon
} from '../utils';
import { IDVDetailsTable } from './IDVDetailsTable';
import {
  JobError,
  JobRequestInfo,
  JobRequestParameters,
  JobRequestProperties,
  JobResult
} from 'models/jobs/jobs';

interface IdDocumentProps {
  readonly jobRequestInfo?: JobRequestInfo;
  readonly jobRequestParams: JobRequestParameters | null;
  readonly jobRequestProperties: JobRequestProperties;
  readonly jobResult: JobResult;
  readonly jobErrors: JobError[];
  readonly jobStatus: JobStatus;
}

export const IdDocument: React.FC<IdDocumentProps> = ({
  jobRequestInfo,
  jobRequestParams,
  jobRequestProperties,
  jobResult,
  jobErrors,
  jobStatus
}) => {
  const idConfidence = jobResult.confidences?.id;
  const idExpiredConfidence = jobResult.confidences?.idExpired;
  const selfieConfidence = jobResult.confidences?.selfie;
  const faceMatchConfidence = jobResult.confidences?.faceMatch;
  const barcodeMatchConfidence = jobResult.confidences?.barcodeMatch;
  const nameMatchConfidence = jobResult.confidences?.nameMatch;
  const birthDateMatchConfidence = jobResult.confidences?.birthDateMatch;
  const barcodePresenceConfidence = jobResult.confidences?.barcode;
  const livenessPerformedConfidence = jobResult.confidences?.liveness;

  const { dropdownHasWarnings, dropdownHasRejections, dropdownItems } =
    useMemo(() => {
      const dropdownItemsWithoutIcons: (
        | BooleanDropdownItem
        | ConfidenceDropdownItem
      )[] = [
        {
          text: 'Valid ID',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          available: typeof idConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(
            jobErrors,
            'InvalidIdPhotoError'
          ),
          tooltip: Tooltips.ValidId
        },
        {
          text: 'Expiration Date',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          available: typeof idExpiredConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(jobErrors, 'ExpiredIdError'),
          tooltip: Tooltips.ExpirationDate
        },
        {
          text: 'Valid Selfie',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          available: typeof selfieConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(
            jobErrors,
            'InvalidUserPhotoError'
          ),
          tooltip: Tooltips.ValidSelfie
        },
        {
          text: 'ID matches Selfie',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          available: typeof faceMatchConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(jobErrors, 'FaceMatchError'),
          tooltip: Tooltips.IdMatchesSelfie
        },
        {
          text: 'ID matches Barcode',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          available: typeof barcodeMatchConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(
            jobErrors,
            'BarcodeMatchError'
          ),
          tooltip: Tooltips.IdMatchesBarcode
        },
        {
          text: 'ID matches Submitted Name',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          available: typeof nameMatchConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(jobErrors, 'NameMatchError'),
          tooltip: Tooltips.IdMatchesSubmittedName
        },
        {
          text: 'ID matches Submitted DOB',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          available: typeof birthDateMatchConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(
            jobErrors,
            'BirthDateMatchError'
          ),
          tooltip: Tooltips.IdMatchesSubmittedDOB
        },
        {
          text: 'Barcode Presence',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          available:
            !!jobResult.clientOutput?.includeBarcode &&
            !!jobResult.hasPDF417Back,
          hasErrorOrWarning: itemHasErrorOrWarning(
            jobErrors,
            'BarcodeDetectError'
          ),
          tooltip: Tooltips.BarcodePresence
        },
        {
          text: 'Selfie Liveness Performed',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          available: typeof livenessPerformedConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(
            jobErrors,
            'LivenessSkippedError'
          ),
          tooltip: Tooltips.SelfieLivenessPerformed
        }
      ];

      return addIconsToDropdownItems(dropdownItemsWithoutIcons);
    }, [
      jobErrors,
      itemHasErrorOrWarning,
      idConfidence,
      idExpiredConfidence,
      selfieConfidence,
      faceMatchConfidence,
      barcodeMatchConfidence,
      nameMatchConfidence,
      birthDateMatchConfidence,
      barcodePresenceConfidence,
      livenessPerformedConfidence
    ]);

  return (
    <Dropdown
      statusIcon={
        <DropdownStatusIcon
          dropdownHasRejections={dropdownHasRejections}
          dropdownHasWarnings={dropdownHasWarnings}
          jobStatus={jobStatus}
        />
      }
      title="Visual IDV"
    >
      <Box>
        <Text>
          This section contains data checks performed against the submitted ID
          Document and Selfie photos.
        </Text>
        <DropdownItemsSection dropdownItems={dropdownItems} />
        <IDVDetailsTable
          deviceData={{
            client: jobResult.clientOutput?.client,
            referer: jobRequestInfo?.referer,
            theme: jobResult.clientOutput?.theme,
            userAgent: jobRequestInfo?.useragent,
            ipAddress: jobRequestInfo?.ipaddress
          }}
          extractedData={{
            birthDate: jobResult.birthDate,
            country: jobResult.country,
            expireDate: jobResult.expireDate,
            firstName: jobResult.firstName,
            id: jobResult.id,
            issueDate: jobResult.issueDate,
            lastName: jobResult.lastName,
            middleName: jobResult.middleName ?? undefined,
            state: jobResult.state,
            type: jobResult.type
          }}
          properties={jobRequestProperties}
          submittedData={{
            dob: jobRequestParams?.dob,
            email: jobRequestParams?.email,
            firstName: jobRequestParams?.firstName,
            lastName: jobRequestParams?.lastName,
            phone: jobRequestParams?.phone
          }}
        />
      </Box>
    </Dropdown>
  );
};
