import { Text } from '@vouched.id/vault';
import React, { FC } from 'react';

export const ReviewCaption: FC = () => (
  <Text>
    Manually review Vouched statuses for the checks below by clicking the icons
    in the Review column. This will create a separate Review Status above for
    the job.
  </Text>
);
