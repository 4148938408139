import { Box, Text, ThemeContext } from '@vouched.id/vault';
import React, { useContext } from 'react';

interface CardProps {
  readonly children?: React.ReactNode;
  readonly title?: string;
  readonly onClick?: () => void;
}
export const Card: React.FC<CardProps> = ({ children, title, onClick }) => {
  const { tokens } = useContext(ThemeContext).theme;

  return (
    <Box
      display="flex"
      flexDirection="column"
      onClick={onClick}
      padding="spacing.large"
      style={{
        borderRadius: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: tokens.shadows.hover,
        cursor: 'pointer',
        backgroundColor: tokens.colors.lightPurple,
        boxSizing: 'border-box'
      }}
    >
      <Text
        style={{
          color: tokens.colors.gray800,
          textTransform: 'uppercase',
          marginBottom: '10px'
        }}
        variation="footnote"
      >
        {title}
      </Text>
      {children}
    </Box>
  );
};
