import { Box } from '@vouched.id/vault';
import React, { FC, useMemo } from 'react';
import { Tooltips } from '../../tooltips';
import { itemHasErrorOrWarning, JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import { DropdownItemsSection } from '../DropdownItemsSection';
import {
  addIconsToDropdownItems,
  BooleanDropdownItem,
  ConfidenceDropdownItem,
  DropdownItemTypes,
  DropdownStatusIcon
} from '../utils';
import { JobError, JobResult } from 'models/jobs/jobs';

interface ReverificationCompletedResultProps {
  readonly jobResult: JobResult;
  readonly jobErrors: JobError[];
  readonly jobStatus: JobStatus;
}

export const ReverificationCompletedResult: FC<
  ReverificationCompletedResultProps
> = ({ jobResult, jobErrors, jobStatus }) => {
  const faceMatchConfidence = jobResult.confidences?.faceMatch;

  const { dropdownHasWarnings, dropdownHasRejections, dropdownItems } =
    useMemo(() => {
      const dropdownItemsWithoutIcons: (
        | BooleanDropdownItem
        | ConfidenceDropdownItem
      )[] = [
        {
          text: 'ID matches Selfie',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          available: typeof faceMatchConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(jobErrors, 'FaceMatchError'),
          tooltip: Tooltips.IdMatchesSelfie
        }
      ];

      return addIconsToDropdownItems(dropdownItemsWithoutIcons);
    }, [
      jobErrors,
      itemHasErrorOrWarning,
      addIconsToDropdownItems,
      faceMatchConfidence
    ]);

  return (
    <Dropdown
      statusIcon={
        <DropdownStatusIcon
          dropdownHasRejections={dropdownHasRejections}
          dropdownHasWarnings={dropdownHasWarnings}
          jobStatus={jobStatus}
        />
      }
      title="Reverification"
    >
      <Box>
        <DropdownItemsSection dropdownItems={dropdownItems} />
      </Box>
    </Dropdown>
  );
};
