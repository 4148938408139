import { Box, Text } from '@vouched.id/vault';
import React, { FC } from 'react';
import { JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import { CrossCheckCaption } from './CrossCheckCaption';
import { CrossCheckCompletedResult } from './CrossCheckCompletedResult';
import { CrossCheckSubmittedData } from './CrossCheckDetailsTables';
import { CrossCheckResult } from 'models/jobs/crosscheck';
import { JobError } from 'models/jobs/jobs';

interface CrossCheckDropdownsProps {
  readonly crossCheckResult: CrossCheckResult | undefined;
  readonly jobErrors: JobError[];
  readonly jobStatus: JobStatus;
  readonly crossCheckSubmittedData: CrossCheckSubmittedData;
}

export const CrossCheckDropdowns: FC<CrossCheckDropdownsProps> = ({
  crossCheckResult,
  jobErrors,
  jobStatus,
  crossCheckSubmittedData
}) => {
  if (!crossCheckResult) {
    return (
      <Dropdown title="CrossCheck">
        <Box>
          <CrossCheckCaption />
          <Text>
            CrossCheck could not be completed. Please try again or contact
            support.
          </Text>
        </Box>
      </Dropdown>
    );
  }

  return (
    <CrossCheckCompletedResult
      crossCheckResult={crossCheckResult}
      crossCheckSubmittedData={crossCheckSubmittedData}
      jobErrors={jobErrors}
      jobStatus={jobStatus}
    />
  );
};
