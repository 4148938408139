import React, { FC } from 'react';

export const UpArrow: FC = () => {
  return (
    <svg
      display="block"
      enableBackground="0 0 24 24"
      fill="none"
      role="img"
      transform="scale(1,-1)"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Up Arrow</title>
      <path d="M18 9L12 15L6 9" stroke="black" strokeLinecap="square" />
    </svg>
  );
};
