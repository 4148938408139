import { Box, Icons, Text, ThemeContext } from '@vouched.id/vault';
import React, { ReactElement, useContext, useState } from 'react';
import { UpArrow } from '../Icons/UpArrow';
import { dropdownWrapperStyle } from './utils';

interface DropdownProps {
  readonly children?: React.ReactNode;
  readonly title?: string;
  readonly statusIcon?: ReactElement;
}
export const Dropdown: React.FC<DropdownProps> = ({
  children,
  title,
  statusIcon
}) => {
  const shadow = useContext(ThemeContext).theme.tokens.shadows.hover;

  const [open, setOpen] = useState(false);
  return (
    <Box style={dropdownWrapperStyle}>
      <Box
        backgroundColor="colors.white"
        display="flex"
        marginBottom="spacing.default"
        onClick={() => {
          setOpen((v) => !v);
        }}
        padding="spacing.default"
        style={{
          borderRadius: '10px',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: shadow,
          cursor: 'pointer'
        }}
      >
        <Text>{title}</Text>
        <Box
          display="flex"
          style={{
            alignItems: 'center'
          }}
        >
          {statusIcon}
          {open ? <UpArrow /> : <Icons.DownArrow />}
        </Box>
      </Box>
      {open && <Box margin="spacing.default">{children}</Box>}
    </Box>
  );
};
