import { Text } from '@vouched.id/vault';
import React, { FC, useEffect, useState } from 'react';
import { JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import { DropdownStatusIcon } from '../utils';
import { AAMVACaption } from './AAMVACaption';
import { AAMVADetailsTable } from './AAMVADetailsTable';
import { AAMVAJobResult } from 'models/jobs/jobs';
import { useGlobalState } from 'store/reducers/reducer';
import { AAMVADetailsResult, retrieveAamvaDetailed } from 'store/sagas/sagas';

interface AAMVACompletedResultProps {
  readonly aamvaJobResult: AAMVAJobResult;
  readonly country?: string;
  readonly jobSid: string;
  readonly jobStatus: JobStatus;
}

export const AAMVACompletedResult: FC<AAMVACompletedResultProps> = ({
  aamvaJobResult,
  country,
  jobSid,
  jobStatus
}) => {
  const { status, statusMessage, hasErrors } = aamvaJobResult;
  const { asyncDispatch } = useGlobalState();
  const [aamvaDetails, setAamvaDetails] = useState<AAMVADetailsResult | null>(
    null
  );

  useEffect(() => {
    asyncDispatch(retrieveAamvaDetailed(jobSid, false))
      .then((details) => {
        setAamvaDetails(details);
      })
      .catch(console.error);
  }, [jobSid]);

  return (
    <Dropdown
      statusIcon={
        status === null ||
        status === 'Pending' ||
        status === 'In Progress' ? undefined : (
          <DropdownStatusIcon
            dropdownHasRejections={status === 'Completed' && hasErrors}
            dropdownHasWarnings={false}
            jobStatus={jobStatus}
          />
        )
      }
      title="DLV"
    >
      <AAMVACaption />
      <Text
        style={{ marginBottom: 'spacing.default' }}
      >{`Status: ${status}`}</Text>
      <Text>{`Status Message: ${statusMessage}`}</Text>
      <AAMVADetailsTable
        aamvaDetails={aamvaDetails}
        aamvaJobResult={aamvaJobResult}
        country={country}
      />
    </Dropdown>
  );
};
