import React, { FC } from 'react';
import { Tooltips } from '../../tooltips';
import { MultiSpanTable, MultiSpanTableEntry } from '../MultiSpanTable';
import { AMLSanctionDetails } from './AMLCompletedResult';

interface AMLDetailsTablesProps {
  readonly sanctions: AMLSanctionDetails[];
}

export const AMLDetailsTables: FC<AMLDetailsTablesProps> = ({ sanctions }) => {
  const entries: MultiSpanTableEntry[] = sanctions.map((sanction, index) => {
    return {
      title: sanction.type.toUpperCase(),
      items: [
        {
          label: 'Listed Name',
          value: sanction.name.toUpperCase(),
          ...(index === 0 && { labelTooltip: Tooltips.ListedNameAML })
        },
        {
          label: 'Listed Url',
          value: sanction.url,
          ...(index === 0 && { labelTooltip: Tooltips.ListedUrlAML })
        },
        {
          label: 'Listed',
          value: sanction.dates.start,
          ...(index === 0 && { labelTooltip: Tooltips.ListedAML })
        },
        {
          label: 'Listing Ended',
          value: sanction.dates.end,
          ...(index === 0 && { labelTooltip: Tooltips.ListingEndedAML })
        }
      ]
    };
  });

  return <MultiSpanTable entries={entries} header="Matched Types" />;
};
