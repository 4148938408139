import './manage-subscription.css';

import { Button, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { cancelSubscription } from 'store/sagas/sagas';
import { isSelfServiceAccount } from 'utils/billing';

export const ManageSubscription: React.FC = () => {
  const { state, dispatch, asyncDispatch } = useGlobalState();

  const [isLoading, setIsLoading] = useState(false);

  const submitCancelSubscription = (event: React.UIEvent) => {
    event.preventDefault();
    setIsLoading(true);
    asyncDispatch(cancelSubscription())
      .then(() => {
        dispatch(
          setMessages({
            severity: 'success',
            value: `Your subscription has been canceled`
          })
        );
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="manage-subscription-main-div">
      <div className="manage-subscription-container">
        <div>
          <h1>Manage Subscription</h1>
          {state.account ? (
            isSelfServiceAccount(state.account) ? (
              <div>
                <p>
                  Your subscription is currently <b>active</b>.
                </p>

                <p>
                  You can cancel your subscription at any time. When your
                  subscription is not active, you will still be able to view and
                  download jobs that have already been created but you will not
                  be able to create new jobs in live mode.
                </p>
                <p>
                  Please note that if you cancel your subscription, you will be
                  sent one final bill for any billable jobs you've created since
                  your last billing statement.
                </p>
                {isLoading ? (
                  <CircularProgress
                    size="1.2rem"
                    style={{ color: '#290e88' }}
                  />
                ) : (
                  <Button onClick={submitCancelSubscription}>Cancel</Button>
                )}
              </div>
            ) : (
              <div>
                <p>
                  Your account's subscription is managed by the Vouched team.
                  Reach out to <a href="mailto:support@vouched.id">support</a>{' '}
                  in order to cancel your subscription.
                </p>
              </div>
            )
          ) : (
            <div>Your account does not have a subscription</div>
          )}
        </div>
      </div>
    </div>
  );
};
