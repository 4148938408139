import { Box, Text } from '@vouched.id/vault';
import React, { FC, useEffect, useState } from 'react';
import { Dropdown } from '../Dropdown';
import { LocationCaption } from './LocationCaption';
import { LocationMap } from './LocationMap';
import { distanceBetweenGoogleLatLong, GoogleLatLong } from './LocationUtils';
import { useGlobalState } from 'store/reducers/reducer';
import { getGeocode } from 'store/sagas/sagas';

interface LocationDropdownsProps {
  readonly idAddressStr: string;
  readonly ipAddressStr: string;
  readonly ipGeocode?: GoogleLatLong;
  readonly crossCheckIpDistance: string;
}

export const LocationDropdowns: FC<LocationDropdownsProps> = ({
  idAddressStr,
  ipAddressStr,
  ipGeocode,
  crossCheckIpDistance
}) => {
  const { asyncDispatch } = useGlobalState();
  const [idGeocode, setIdGeocode] = useState<GoogleLatLong | undefined>(
    undefined
  );

  const [distanceBetweenStr, setDistanceBetweenStr] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (!idAddressStr) {
      return;
    }

    asyncDispatch(getGeocode(idAddressStr)).then((response) => {
      if (!response) {
        return;
      }

      setIdGeocode(response.result);
      if (crossCheckIpDistance) {
        setDistanceBetweenStr(crossCheckIpDistance);
      } else if (ipGeocode) {
        setDistanceBetweenStr(
          distanceBetweenGoogleLatLong(response.result, ipGeocode).toFixed(2)
        );
      }
    }, console.error);
  }, []);

  return (
    <Dropdown title="Location">
      <Box>
        <LocationCaption />
        <Box
          display="grid"
          marginBottom="spacing.default"
          style={{
            gridTemplateColumns: 'auto 1fr',
            justifyItems: 'start',
            gridGap: '10px'
          }}
        >
          <Text style={{ fontWeight: 'bold' }}>{`ID Address: `}</Text>
          <Text>{idAddressStr || 'N/A'}</Text>
          <Text style={{ fontWeight: 'bold' }}>{`IP Address: `}</Text>
          <Text>{ipAddressStr || 'N/A'}</Text>
          <Text style={{ fontWeight: 'bold' }}>{`Distance Between: `}</Text>
          <Text>
            {distanceBetweenStr ? `${distanceBetweenStr} Miles` : `N/A`}
          </Text>
        </Box>
        <LocationMap idGeocode={idGeocode} ipGeocode={ipGeocode} />
      </Box>
    </Dropdown>
  );
};
