import { CompanyAccount } from 'models/company-account/company-account.model';

const SUBSCRIPTION_TIERS = {
  SELF_SERVICE: 'QSRNDHPfV'
} as const;

const isSelfServiceTier = (subscriptionTier: string | null): boolean => {
  return subscriptionTier === SUBSCRIPTION_TIERS.SELF_SERVICE;
};

export const isSelfServiceAccount = (account: CompanyAccount): boolean => {
  return (
    !!account.stripeCustomerId && isSelfServiceTier(account.subscriptionTier)
  );
};
