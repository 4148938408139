import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import chunk from 'lodash.chunk';
import React, { FC } from 'react';
import { readableBoolean } from 'components/account/jobs-v2/dropdowns/utils';
import { List } from 'components/reusable/list';
import { Text } from 'components/text';
import { AAMVADetailsResult } from 'store/sagas/sagas';

export interface AAMVARequestData {
  licenceNumber?: string;
  licenseNumber?: string;
  state: string;
  province: string;
  dateOfBirth: string;
  birthDate: string;
  issueDate: string;
  expirationDate: string;
  lastName: string;
  firstName: string;
}

export interface AAMVAResponseData {
  response: {
    status: string;
    statusCode: number;
  };
  state: boolean;
  expirationDate: boolean;
  issueDate: boolean;
  birthDate: boolean;
  lastNameFuzzy: boolean;
  lastName: boolean;
  licenseType: boolean;
  licenseNumber: boolean;
}

const getAamvaLicenseNumber = (requestData: AAMVARequestData) => {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return requestData.licenceNumber || requestData.licenseNumber;
};

const getAamvaState = (requestData: AAMVARequestData) => {
  return requestData.state || requestData.province;
};

const getAamvaDob = (requestData: AAMVARequestData) => {
  return requestData.dateOfBirth || requestData.birthDate;
};

export const DetailedAamva: FC<{
  readonly detailData: AAMVADetailsResult;
  readonly country?: string;
}> = ({ detailData, country }) => {
  let requestData;
  if (detailData.requestData) {
    try {
      requestData = JSON.parse(detailData.requestData) as AAMVARequestData;
    } catch {
      console.error('Unable to parse JSON');
    }
  }

  let responseData;
  if (detailData.responseData) {
    try {
      responseData = JSON.parse(detailData.responseData) as AAMVAResponseData;
    } catch {
      console.error('Unable to parse JSON');
    }
  }

  return (
    <List
      columns={[
        {
          name: 'name',
          id: 'name',
          xs: 2
        },
        {
          name: 'type',
          xs: 2,
          customRenderValue: (item) =>
            typeof item.customRenderType === 'function'
              ? item.customRenderType()
              : item.customRenderType
        },
        {
          name: 'value',
          xs: 8,
          customRenderValue: (item) =>
            typeof item.customRenderValue === 'function'
              ? item.customRenderValue()
              : item.customRenderValue
        }
      ]}
      items={[
        {
          name: 'Request Data',
          customRenderType: (
            <div className="job-modal-ip">
              <Text strongest={false} value="License Number" />
              <Text strongest={false} value="Last Name" />
              <Text strongest={false} value="State" />
              <Text strongest={false} value="Date of Birth" />
              {country === 'CA' ? (
                <Text strongest={false} value="First Name" />
              ) : null}
              {country === 'US' ? (
                <Text strongest={false} value="Issue Date" />
              ) : null}
              {country === 'US' ? (
                <Text strongest={false} value="Expiration Date" />
              ) : null}
            </div>
          ),
          customRenderValue: (
            <div className="job-modal-ip">
              <Text
                value={
                  requestData ? getAamvaLicenseNumber(requestData) : undefined
                }
              />
              <Text value={requestData?.lastName} />
              <Text
                value={requestData ? getAamvaState(requestData) : undefined}
              />
              <Text
                value={requestData ? getAamvaDob(requestData) : undefined}
              />
              {country === 'CA' ? (
                <Text value={requestData?.firstName} />
              ) : null}
              {country === 'US' ? (
                <Text value={requestData?.issueDate} />
              ) : null}
              {country === 'US' ? (
                <Text value={requestData?.expirationDate} />
              ) : null}
            </div>
          )
        },
        {
          name: 'Response Data',
          customRenderType: (
            <div className="job-modal-ip">
              {country === 'CA' ? (
                <Text strongest={false} value="Status" />
              ) : null}
              {country === 'CA' ? (
                <Text strongest={false} value="Status Code" />
              ) : null}
              {country !== 'CA' && responseData?.licenseNumber ? (
                <Text strongest={false} value="License Number" />
              ) : null}
              {country !== 'CA' && responseData?.licenseType ? (
                <Text strongest={false} value="License Type" />
              ) : null}
              {country !== 'CA' && responseData?.lastName ? (
                <Text strongest={false} value="Last Name" />
              ) : null}
              {country !== 'CA' && responseData?.lastNameFuzzy ? (
                <Text strongest={false} value="Last Name Fuzzy" />
              ) : null}
              {country !== 'CA' && responseData?.birthDate ? (
                <Text strongest={false} value="Birth Date" />
              ) : null}
              {country !== 'CA' && responseData?.issueDate ? (
                <Text strongest={false} value="Issue Date" />
              ) : null}
              {country !== 'CA' && responseData?.expirationDate ? (
                <Text strongest={false} value="Expiration Date" />
              ) : null}
              {country !== 'CA' && responseData?.state ? (
                <Text strongest={false} value="State" />
              ) : null}
            </div>
          ),
          customRenderValue: (
            <div className="job-modal-ip">
              {country === 'CA' ? (
                <Text value={responseData?.response.status} />
              ) : null}
              {country === 'CA' ? (
                <Text value={responseData?.response.statusCode} />
              ) : null}
              {country !== 'CA' && responseData?.licenseNumber !== undefined ? (
                <Text value={readableBoolean(responseData.licenseNumber)} />
              ) : null}
              {country !== 'CA' && responseData?.licenseType !== undefined ? (
                <Text value={readableBoolean(responseData.licenseType)} />
              ) : null}
              {country !== 'CA' && responseData?.lastName !== undefined ? (
                <Text value={readableBoolean(responseData.lastName)} />
              ) : null}
              {country !== 'CA' && responseData?.lastNameFuzzy !== undefined ? (
                <Text value={readableBoolean(responseData.lastNameFuzzy)} />
              ) : null}
              {country !== 'CA' && responseData?.birthDate !== undefined ? (
                <Text value={readableBoolean(responseData.birthDate)} />
              ) : null}
              {country !== 'CA' && responseData?.issueDate !== undefined ? (
                <Text value={readableBoolean(responseData.issueDate)} />
              ) : null}
              {country !== 'CA' &&
              responseData?.expirationDate !== undefined ? (
                <Text value={readableBoolean(responseData.expirationDate)} />
              ) : null}
              {country !== 'CA' && responseData?.state !== undefined ? (
                <Text value={readableBoolean(responseData.state)} />
              ) : null}
            </div>
          )
        },
        {
          name: 'Detailed Warnings',
          customRenderType: (): null => {
            return null;
          },
          customRenderValue: () => {
            const warningStr = detailData.warnings;

            if (warningStr === undefined || warningStr === null) {
              return null;
            }

            const warnings = JSON.parse(warningStr) as
              | { warnings: string[] }
              | undefined;

            const chunkedWarnings = warnings ? chunk(warnings.warnings, 3) : [];

            return (
              <Table>
                <TableBody>
                  {chunkedWarnings.map((warn: string[]) => {
                    return (
                      <TableRow key={warn.join('')}>
                        {warn.map((warning: string) => {
                          return (
                            <TableCell key={warning}>
                              <Text value={warning} />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            );
          }
        },
        {
          name: 'Detailed Errors',
          customRenderType: (): null => {
            return null;
          },
          customRenderValue: () => {
            const errorStr = detailData.errors;

            if (errorStr === undefined || errorStr === null) {
              return null;
            }

            const errors = JSON.parse(errorStr) as
              | { errors: string[] }
              | undefined;

            const chunkedErrors = errors ? chunk(errors.errors, 3) : [];

            return (
              <Table>
                <TableBody>
                  {chunkedErrors.map((err) => {
                    return (
                      <TableRow key={err.join('')}>
                        {err.map((error: string) => {
                          return (
                            <TableCell key={error}>
                              <Text value={error} />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            );
          }
        }
      ]}
      page={1}
      showHeaders={false}
    />
  );
};
