import { Box, Text } from '@vouched.id/vault';
import React, { FC, useCallback, useState } from 'react';
import { DropdownItem } from '../DropdownItemsSection';
import { Tooltip } from '../Tooltip';
import { useTableStyles } from '../utils';
import { ReviewIcon } from './ReviewIcon';
import { JobUpdateReview } from 'models/jobs/jobs';
import { useGlobalState } from 'store/reducers/reducer';
import { updateReview } from 'store/sagas/sagas';

export type JobReviewSingleItem = {
  [key in keyof JobUpdateReview]: boolean | null;
};

interface ReviewTableProps {
  readonly dropdownItems: DropdownItem[];
  readonly jobReview: JobUpdateReview | null;
  readonly jobSid: string;
  readonly setJobReviewSuccess: (success: boolean | null) => void;
}

export const ReviewTable: FC<ReviewTableProps> = ({
  dropdownItems,
  jobReview,
  jobSid,
  setJobReviewSuccess
}) => {
  const { asyncDispatch } = useGlobalState();
  const [reviewItems, setReviewItems] = useState<JobUpdateReview>(
    jobReview ?? {}
  );

  const handleReviewUpdate = useCallback(
    (item: JobReviewSingleItem) => {
      const updatedReviewItems = { ...reviewItems, ...item };
      setReviewItems(updatedReviewItems);
      if (Object.values(updatedReviewItems).every((value) => value === null)) {
        setJobReviewSuccess(null);
      } else {
        setJobReviewSuccess(!Object.values(updatedReviewItems).includes(false));
      }

      asyncDispatch(updateReview(jobSid, updatedReviewItems, false)).catch(
        console.error
      );
    },
    [reviewItems]
  );

  const { tableStyles, thStyles, tdStyles } = useTableStyles();

  return (
    <table style={tableStyles}>
      <tbody>
        <tr>
          <th style={thStyles}>Field</th>
          <th style={thStyles}>Vouched</th>
          <th style={thStyles}>Review</th>
        </tr>
        {dropdownItems.map((item) => (
          <tr key={item.text}>
            <td style={tdStyles}>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="flex-start"
                style={{ gap: '5px' }}
              >
                <Text>{item.text}</Text>
                {item.tooltip && (
                  <Box>
                    <Tooltip>{item.tooltip}</Tooltip>
                  </Box>
                )}
              </Box>
            </td>
            <td style={tdStyles}>
              <Box alignItems="center" display="flex">
                {item.statusIcon}
              </Box>
            </td>
            <td style={tdStyles}>
              <Box alignItems="center" display="flex">
                <ReviewIcon
                  handleReviewUpdate={handleReviewUpdate}
                  label={item.reviewLabel!}
                  originalResultIcon={item.statusIcon}
                  reviewItems={reviewItems}
                />
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
