import { Text } from '@vouched.id/vault';
import React, { FC } from 'react';

export const AAMVACaption: FC<{ readonly dataUnavailable?: boolean }> = ({
  dataUnavailable = false
}) => {
  return (
    <>
      <Text style={{ marginBottom: 'spacing.default' }}>
        Driver's License Verification (DLV) through AAMVA DMV Verification
        verifies a person's driver's license or state ID card by validating it
        against the database of the motor vehicle association of the state
        associated with the ID.
      </Text>
      {dataUnavailable && (
        <Text>
          DLV was enabled for this job, but if you are seeing this message,
          either the state that issued the driver's license is not available for
          DLV, the issuer is not from the United States, or the submitted
          document is not a driver's license. For more information, please reach
          out to support@vouched.id.
        </Text>
      )}
    </>
  );
};
