import { MessageProps } from 'components/message';
import { AuthData } from 'models/auth-data/auth-data.model';
import { TwoFactorData } from 'models/auth-data/two-factor-data.model';
import {
  CompanyAccount,
  CompanyAccountGroup
} from 'models/company-account/company-account.model';
import { User } from 'models/user/user.model';
import { Subscription } from 'store/reducers/reducer';
import { CustomerInfo, Invoice, PaymentMethod } from 'store/sagas/sagas';
import { history } from 'utils/history';

export const SET_2FA_DATA = '@AUTH/SET_2FA_DATA';
export const SET_AUTHDATA = '@auth/SET_AUTHDATA';
export const SET_USER = '@auth/SET_USER';
export const SET_USER_ACCOUNT_GROUPS = '@auth/SET_USER_ACCOUNT_GROUPS';
export const SET_ACCOUNT_GROUPS = '@auth/SET_ACCOUNT_GROUPS';
export const SET_ACCOUNT = '@auth/SET_ACCOUNT';
export const SET_ACCOUNTS = '@auth/SET_ACCOUNTS';
export const SET_SEARCHED_USERS = '@auth/SET_SEARCHED_USERS';
export const LOGOUT = '@auth/LOGOUT';
export const SET_MESSAGES = '@global/SET_MESSAGES';

export const SET_PAYMENT_METHODS = '@payments/SET_PAYMENT_METHODS';
export const SET_INVOICES = '@payments/SET_INVOICES';
export const SET_CURRENT_SUBSCRIPTION = '@payments/SET_CURRENT_SUBSCRIPTION';
export const SET_CURRENT_CUSTOMER = '@payments/SET_CURRENT_CUSTOMER';

export const SET_SANDBOX_TOGGLED = '@global/SET_SANDBOX_TOGGLED';

interface SetMessagesAction {
  type: typeof SET_MESSAGES;
  payload: MessageProps[];
}

interface SetAuthDataAction {
  type: typeof SET_AUTHDATA;
  payload: AuthData;
}

interface SetUserAction {
  type: typeof SET_USER;
  payload: {
    user: User;
  };
}

interface SetUserAccountGroupsAction {
  type: typeof SET_USER_ACCOUNT_GROUPS;
  payload: {
    userAccountGroups: CompanyAccountGroup[];
  };
}

interface SetAccountsActions {
  type: typeof SET_ACCOUNTS;
  payload: {
    accounts: CompanyAccount[];
  };
}

interface SetAccountGroupsAction {
  type: typeof SET_ACCOUNT_GROUPS;
  payload: {
    accountGroups: CompanyAccountGroup[];
  };
}

interface SetAccountAction {
  type: typeof SET_ACCOUNT;
  payload: {
    account: CompanyAccount;
  };
}

interface LogoutAction {
  type: typeof LOGOUT;
  payload: {
    user: null;
    account: CompanyAccount | null;
    accessToken: string;
  };
}

interface Set2FAAction {
  type: typeof SET_2FA_DATA;
  payload: TwoFactorData;
}

interface SetPaymentMethodsAction {
  type: typeof SET_PAYMENT_METHODS;
  payload: {
    paymentMethods: PaymentMethod[];
  };
}

interface SetInvoicesAction {
  type: typeof SET_INVOICES;
  payload: {
    invoices: Invoice[];
  };
}

interface SetCurrentSubscriptionAction {
  type: typeof SET_CURRENT_SUBSCRIPTION;
  payload: {
    currentSubscription: Subscription;
  };
}

interface SetCurrentCustomerAction {
  type: typeof SET_CURRENT_CUSTOMER;
  payload: {
    currentCustomer: CustomerInfo;
  };
}

interface SetSandboxToggledAction {
  type: typeof SET_SANDBOX_TOGGLED;
  payload: {
    sandboxToggled: boolean;
  };
}

interface SetSearchedUsersAction {
  type: typeof SET_SEARCHED_USERS;
  payload: {
    searchedUsers: User[];
  };
}

export type Action =
  | LogoutAction
  | Set2FAAction
  | SetAccountAction
  | SetAccountGroupsAction
  | SetAccountsActions
  | SetAuthDataAction
  | SetCurrentCustomerAction
  | SetCurrentSubscriptionAction
  | SetInvoicesAction
  | SetMessagesAction
  | SetPaymentMethodsAction
  | SetSandboxToggledAction
  | SetSearchedUsersAction
  | SetUserAccountGroupsAction
  | SetUserAction;

export const setMessages = (
  messages: MessageProps | MessageProps[]
): SetMessagesAction => {
  if (!Array.isArray(messages)) {
    messages = [messages];
  }

  return {
    type: SET_MESSAGES,
    payload: messages
  };
};

export const setAuthData = (data: AuthData): SetAuthDataAction => {
  localStorage.setItem('accessToken', data.accessToken);
  return {
    type: SET_AUTHDATA,
    payload: {
      user: data.user,
      account: data.account,
      accessToken: data.accessToken,
      resToken: data.resToken
    }
  };
};

export const setUser = (user: User): SetUserAction => {
  return {
    type: SET_USER,
    payload: {
      user
    }
  };
};

export const setUserAccountGroups = (
  userAccountGroups: CompanyAccountGroup[]
): SetUserAccountGroupsAction => {
  return {
    type: SET_USER_ACCOUNT_GROUPS,
    payload: {
      userAccountGroups
    }
  };
};

export const setAccountGroups = (
  accountGroups: CompanyAccountGroup[]
): SetAccountGroupsAction => {
  return {
    type: SET_ACCOUNT_GROUPS,
    payload: {
      accountGroups
    }
  };
};

export const setAccounts = (accounts: CompanyAccount[]): SetAccountsActions => {
  return {
    type: SET_ACCOUNTS,
    payload: {
      accounts
    }
  };
};

export const setAccount = (account: CompanyAccount): SetAccountAction => {
  return {
    type: SET_ACCOUNT,
    payload: {
      account
    }
  };
};

export const logout = (): LogoutAction => {
  localStorage.removeItem('authData');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('sandboxToggled');

  history.push('/signin');
  return {
    type: LOGOUT,
    payload: {
      user: null,
      // TODO: Make this nullable!
      account: null,
      accessToken: ''
    }
  };
};

export const set2FAData = (data: TwoFactorData): Set2FAAction => ({
  type: SET_2FA_DATA,
  payload: {
    otpToken: data.otpToken,
    otpImage: data.otpImage,
    resToken: data.resToken
  }
});

export const setPaymentMethods = (
  data: PaymentMethod[]
): SetPaymentMethodsAction => ({
  type: SET_PAYMENT_METHODS,
  payload: {
    paymentMethods: data
  }
});

export const setInvoices = (invoices: Invoice[]): SetInvoicesAction => ({
  type: SET_INVOICES,
  payload: {
    invoices
  }
});

export const setCurrentCustomer = (
  currentCustomer: CustomerInfo
): SetCurrentCustomerAction => ({
  type: SET_CURRENT_CUSTOMER,
  payload: {
    currentCustomer
  }
});

export const setSandboxToggled = (
  sandboxToggled: boolean
): SetSandboxToggledAction => {
  localStorage.setItem('sandboxToggled', sandboxToggled.toString());
  window.location.reload();
  return {
    type: SET_SANDBOX_TOGGLED,
    payload: {
      sandboxToggled
    }
  };
};

export const setSearchedUsers = (users: User[]): SetSearchedUsersAction => {
  return {
    type: SET_SEARCHED_USERS,
    payload: {
      searchedUsers: users
    }
  };
};
