import { Box, Text, ThemeContext } from '@vouched.id/vault';
import React, { FC, ReactElement, useContext } from 'react';
import { Tooltip } from './Tooltip';
import { useTableStyles } from './utils';

export interface SingleSpanTableItem {
  label: string;
  value?: number | string;
  valueColor?: string;
  statusIcon?: ReactElement;
  labelTooltip?: string;
}
interface SingleSpanTableProps {
  readonly caption?: string;
  readonly numColumns: 2 | 3;
  readonly items: SingleSpanTableItem[];
}

export const SingleSpanTable: FC<SingleSpanTableProps> = ({
  caption,
  numColumns,
  items
}) => {
  const { tokens } = useContext(ThemeContext).theme;
  const { captionStyles, tableStyles, thStyles, tdStyles } = useTableStyles();

  return (
    <Box marginY="spacing.xlarge">
      <table style={tableStyles}>
        {caption && <caption style={captionStyles}>{caption}</caption>}
        <tbody>
          <tr>
            <th style={thStyles}>Field</th>
            <th style={thStyles}>Value</th>
            {numColumns === 3 && <th style={thStyles}>Result</th>}
          </tr>
          {items.map((item) => (
            <tr key={item.label}>
              <td style={tdStyles}>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="flex-start"
                  style={{ gap: '5px' }}
                >
                  <Text
                    style={{
                      ...(item.value === undefined && {
                        color: tokens.colors.textInverseMuted
                      })
                    }}
                  >
                    {item.label}
                  </Text>
                  {item.labelTooltip && (
                    <Box>
                      <Tooltip>{item.labelTooltip}</Tooltip>
                    </Box>
                  )}
                </Box>
              </td>
              <td style={tdStyles}>
                <Text
                  style={{ ...(item.valueColor && { color: item.valueColor }) }}
                >
                  {item.value}
                </Text>
              </td>
              {numColumns === 3 && <td style={tdStyles}>{item.statusIcon}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};
